import axios from 'axios';

const create = (newCategory, token) => axios.post(`${global.applicationApiEndpoint}/categories`, newCategory, mountHeader(token));
const getList = (token) => axios.get(`${global.applicationApiEndpoint}/categories`, mountHeader(token));
const getById = (categoryId, token) => axios.get(`${global.applicationApiEndpoint}/categories/${categoryId}`, mountHeader(token));
const update = (category, categoryId, token) => axios.put(`${global.applicationApiEndpoint}/categories/${categoryId}`, category, mountHeader(token));

const mountHeader = (token) => {
    return { headers: { 'token': token } };
}

export default {
    create,
    getList,
    getById,
    update
}