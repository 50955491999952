import axios from 'axios';

const create = (newStudent, token) => {
    return axios.post(`${global.applicationApiEndpoint}/students`, newStudent, _mountAuthenticateHeader(token))
}

const getList = (token) => {
    return axios.get(`${global.applicationApiEndpoint}/students`, _mountAuthenticateHeader(token))
}

const remove = (StudentId, token) => {
    return axios.delete(`${global.applicationApiEndpoint}/students/${StudentId}`, _mountAuthenticateHeader(token))
}

const getById = (StudentId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/students/${StudentId}`, _mountAuthenticateHeader(token));
}

const update = (Student, StudentId, token) => {
    return axios.put(`${global.applicationApiEndpoint}/students/${StudentId}`, Student, _mountAuthenticateHeader(token));
}

const getByCourseId = (courseId, classId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/students/course/${courseId}/${classId}`, _mountAuthenticateHeader(token))
}

const updatePresence = (studentList, token) => {
    return axios.post(`${global.applicationApiEndpoint}/students/presence`, studentList, _mountAuthenticateHeader(token))
}

const _mountAuthenticateHeader = (token) => {
    return { headers: { 'token': token } };
}


export default {
    create,
    getList,
    remove,
    getById,
    update,
    getByCourseId,
    updatePresence
}