<template>
    <v-app>
        <Menu></Menu>
        <v-main>
            <v-container fluid style="padding: 25px;">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <CategoriesMainComponent></CategoriesMainComponent>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Menu from "../components/global/Menu";
import CategoriesMainComponent from "../components/widgets/categories/CategoriesMainComponent";

export default {
    name: "Categorias",
    components: {Menu, CategoriesMainComponent},
    data: () => ({}),
    beforeCreate: function() {
        if(!this.$store.state.user) this.$router.push("/login");
    }
}
</script>