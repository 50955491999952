<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Turmas</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="goback()">
                    voltar
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-card elevation="2" style="padding: 25px;">
                <v-card-title>
                    <h4>Cadastro de Turma:</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Nome da Turma:" type="text" v-model="course.name"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-select v-model="course.module" :items="moduleList" item-text="text" item-value="value" label="Módulo:"></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Inicio:" type="date" v-model="course.initDate"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Fim:" type="date" v-model="course.endDate"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Ano:" type="text" v-model="course.year"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Semestre:" type="text" v-model="course.semester"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-textarea label="Descrição:" v-model="course.description"></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <h4>Recorrencia das Aulas:</h4>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-checkbox v-model="course.sunday" label="Domingo" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>

                        <v-col cols="2">
                            <v-checkbox v-model="course.monday" label="Segunda" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-checkbox v-model="course.tuesday" label="Terça" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-checkbox v-model="course.wednesday" label="Quarta" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-checkbox v-model="course.thursday" label="Quinta" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>

                        <v-col cols="2">
                            <v-checkbox v-model="course.friday" label="Sexta" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-checkbox v-model="course.saturday" label="Sabado" :color="$store.state.colors.primary"></v-checkbox>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-text-field label="Horario De inicio:" type="time" v-model="course.initHour"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <div align="right" style="width: 100%">
                            <v-btn 
                                :color="$store.state.colors.primary" 
                                dark 
                                large 
                                :loading="cardLoading"
                                @click="updateCourse()">
                                salvar
                            </v-btn>
                        </div>
                    </v-row>

                    <br> 
                    <v-divider></v-divider>
                    <br>
                    
                    <h4>Lista de Aulas:</h4>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="findClass"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                                placeholder="Buscar">
                            </v-text-field>
                        </v-col>
                        <v-col cols="8" align="right">
                            <v-btn 
                                dark 
                                fab 
                                small
                                :color="$store.state.colors.primary"
                                @click="loadClassesByCourse($route.params.courseId)">
                                    <v-icon>mdi-refresh</v-icon>
                            </v-btn> &nbsp;
                            <v-btn 
                                :color="$store.state.colors.primary"
                                dark
                                large
                                @click="showNewClassForm = !showNewClassForm">
                                    nova
                            </v-btn> &nbsp;
                            <v-btn 
                                :color="$store.state.colors.primary"
                                dark
                                large
                                @click="exportClassList()">
                                    exportar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <br>
                    <v-skeleton-loader
                        v-if="elementsNotReady"
                        elevation="2"
                        type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
                    </v-skeleton-loader>


                    <v-data-table v-else
                        :headers="classListHeader" 
                        :items="classList" 
                        :items-per-page="9999999" 
                        :search="findClass"
                        class="elevation-1">

                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon :color="$store.state.colors.primary" @click="editClass(item)">mdi-pencil</v-icon>
                        </template>
                        <template v-slot:[`item.presence`]="{ item }">
                            <v-icon :color="$store.state.colors.primary" @click="setStudantPresence(item)">mdi-clipboard-edit</v-icon>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-icon :color="$store.state.colors.danger" @click="deleteClass(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>

                <SistemMessage ref="message"></SistemMessage>
            </v-card>
        </v-card-text>

        <v-dialog v-model="showPresenceDialog" width="1000">
            <v-card elevation="0">
                <v-card-title>
                    <h4>Lista de presença dia {{selectedClassDate}}:</h4>
                    <v-spacer></v-spacer>
                    <v-icon @click="showPresenceDialog = !showPresenceDialog">mdi-close-thick</v-icon>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="findStudent"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                                placeholder="Buscar">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <br>
                    <v-data-table 
                        :headers="studentListHeader" 
                        :items="studentList" 
                        :items-per-page="5" 
                        :search="findStudent"
                        class="elevation-1"
                        :footer-props="{
                            showFirstLastPage: true,
                            'items-per-page-text':'Participantes por Página'}">

                        <template v-slot:[`item.present`]="{ item }">
                            <v-checkbox v-model="item.presence" :color="$store.state.colors.primary"></v-checkbox>
                        </template>
                    </v-data-table>
                    <br>
                    <v-row>
                        <div align="right" style="width: 100%; padding: 10px;">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                :loading="cardLoading"
                                @click="changeStudent()">
                                    salvar
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showClassEdit" width="1000">
            <v-card>
                <v-card-title>
                    <h4>Atualização da Aula</h4>
                    <v-spacer></v-spacer>
                    <v-icon @click="showClassEdit = !showClassEdit">mdi-close-thick</v-icon>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Titulo:" v-model="selectedClass.name"></v-text-field>
                        </v-col>

                        <v-col cols="5">
                            <v-text-field label="Aula:" v-model="selectedClass.class"></v-text-field>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-text-field label="Data:" type="date" v-model="selectedClass.date"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Professor" v-model="selectedClass.teacher"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div align="right" style="width: 100%">
                                <v-btn :color="$store.state.colors.primary"
                                    dark
                                    large
                                    :loading="cardLoading"
                                    @click="changeClass()">
                                        salvar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showNewClassForm" width="1000">
            <v-card elevation="2" app :loading="cardLoading">
                <v-card-title>
                    <h4>Cadastro de Aula</h4>  
                    <v-spacer></v-spacer>
                    <v-icon @click="showNewClassForm = !showNewClassForm">mdi-close-thick</v-icon>
                </v-card-title>

                <v-card-text style="padding: 25px;">
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Tema:" v-model="newClass.name"></v-text-field>
                        </v-col>

                        <v-col cols="5">
                            <v-text-field label="Aula:" v-model="newClass.class"></v-text-field>
                        </v-col>

                        <v-col cols="2">
                            <v-text-field label="Data:" :items="modules" type="date" v-model="newClass.date"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Professor:" v-model="newClass.teacher"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div align="right" style="width: 100%">
                                <v-btn :color="$store.state.colors.primary"
                                    dark
                                    large
                                    :loading="cardLoading"
                                    @click="createNewClass()">
                                        salvar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import SistemMessage from "../../global/SistemMessage";
import CourseService from "../../../services/CourseService";
import StudentService from "../../../services/StudentService";
import ClassService from "../../../services/ClassService";
import csvHandler from "../../../services/csvHandler";

export default {
    name: "CourseEdit",

    components: { SistemMessage },

    data: () => ({
        elementsNotReady: false,
        selectedClassDate: undefined,
        cardLoading: false,
        course: undefined,
        findClass: undefined,
        findStudent: undefined,
        presenceReport: undefined,
        moduleList: [
            {value: 1, text: "Modulo 1"},
            {value: 2, text: "Modulo 2"},
            {value: 3, text: "Modulo 3"},
        ],
        classListHeader: [
            { text: 'ID', sortable: true, value: 'id', align: "start"},
            { text: 'Tema', sortable: true, value: 'name', align: "start"},
            { text: 'Professor', sortable: true, value: 'teacher'},
            { text: 'Aula', sortable: true, value: 'class'},
            { text: 'Data', sortable: true, value: 'date'},
            { text: 'Editar', sortable: true, value: 'edit', align: "end"},
            { text: 'Presença', sortable: true, value: 'presence', align: "end"},
            { text: 'Excluir', sortable: true, value: 'delete', align: "end"}
        ],
        classList: undefined,
        selectedClass: {
            courseId: undefined, 
            name: undefined,
            class: undefined,
            teacher: undefined,
            date: undefined,     
            id: undefined,
        },
        studentListHeader: [
            { text: 'ID', align: 'start', sortable: true, value: 'studentId' },
            { text: 'Nome', sortable: true, value: 'name' },
            { text: 'CPF', sortable: true, value: 'cpf' },
            { text: 'Turma', sortable: true, value: 'course' },
            { text: 'Presença', sortable: true, value: 'present' },
        ],
        studentList: undefined,
        showPresenceDialog: false,
        showClassEdit: false,
        newClass: {
            id:undefined,
            name: undefined,
            class: undefined,
            teacher: undefined,
            module: undefined,
            date: undefined,
            courseId: undefined,
        },
        modules: [
            {value: 1, text: "Modulo 1"},
            {value: 2, text: "Modulo 2"},
            {value: 3, text: "Modulo 3"}
        ],
        showNewClassForm: false,
    }),

    methods: {
        validateFields() {
            if(!this.course.name || !this.course.module) return false;
            return true;
        },

        updateCourse() {
            this.cardLoading = true;
            if(!this.validateFields()) {
                this.$refs.message.open(
                    "Atualização de Turma",
                    "Existem campos obrigatórios não preenchidos. Preencha todos os campos e tente novamente",
                    {color: this.$store.state.colors.warning , showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }

            this.cardLoading = true;
            CourseService
                .update(this.course, this.$route.params.courseId, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Atualização de turma",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.goback());
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao atualizar turma",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                }).finally(() => this.cardLoading = false);
        },

        goback() {
            this.$cookies.set("actualPage", "/curso")
            this.$router.push("/curso");
        },

        setStudantPresence(item) {
            this.selectedClassDate = item.date;
            StudentService
                .getByCourseId(this.$route.params.courseId, item.id, this.$store.state.token)
                .then(response => {
                    this.studentList = response.data.students;
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar Participantes",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
            this.showPresenceDialog = true;
        },

        editClass(item) {
            const auxDate = !item.date ? false : item.date.split("/");

            this.selectedClass.courseId = +this.$route.params.courseId;
            this.selectedClass.id = item.id
            this.selectedClass.name = item.name
            this.selectedClass.class = item.class
            this.selectedClass.teacher = item.teacher
            this.selectedClass.date = !auxDate ? "" : auxDate[0] + "-" + auxDate[1] + "-" + auxDate[2];   
            this.showClassEdit = true;
        },

        changeClass() {
            ClassService
                .updateClassByCourse(this.selectedClass, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Atualização de aula",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => {
                        this.showClassEdit = false;
                        this.loadClassesByCourse(this.$route.params.courseId)
                    })
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao atualizar aula",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        },

        changeStudent() {
            StudentService
                .updatePresence(this.studentList)
                .then(response => {
                    this.$refs.message.open(
                        "Lista de presença",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.showPresenceDialog = false)
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao atualiza lista de presença",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        },

        loadCourse(id) {
            CourseService
                .getById(id, this.$store.state.token)
                .then(response => {
                    this.course = response.data.course;
                    this.course.initDate =  this.course.initDate.split("T")[0];
                    this.course.endDate = this.course.endDate.split("T")[0];
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar dados do curso",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        },

        loadStudentsByCourse() {
            StudentService
                .getByCourseId(this.$route.params.courseId, this.$store.state.token)
                .then(response => this.studentList = response.data.students)
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar lista de alunos para essa aula",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        },

        loadClassesByCourse(courseId) {
            this.elementsNotReady = true;
            ClassService
                .getByCourseId(courseId, this.$store.state.token)
                .then(response => {
                    this.classList = response.data.classes
                    this.classList.forEach(element => {
                        element.date = element.date?.split("T")[0].split("-").join("/")
                    })
                    this.presenceReport = response.data.presenceReport
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar aulas para esta turma",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                }).finally(() => this.elementsNotReady = false);
        },

        deleteClass(selectedClass) {
            this.$refs.message.open(
                "Exclusão de Aula",
                "Tem certeza que deseja remover a aula selecionada? Esta ação é irreversível",
                {color: this.$store.state.colors.danger, showSecondButton: true, firstButtonColor: this.$store.state.colors.danger}
            ).then(() => {
                ClassService
                    .remove(selectedClass.id, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Exclusão de Aula",
                            response.data.message,
                            {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                        )
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Exclusão de Aula",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.loadClassesByCourse(this.$route.params.courseId));
            });

        },

        exportClassList() {
            csvHandler.expostClassList(this.classList)
        },

        validateClassFields() {
            if(this.newClass.name || this.newClass.teacher || this.newClass.class || this.newClass.module) return true;
            return false;
        },

        createNewClass() {
            if(!this.validateClassFields()) {
                this.$refs.message.open(
                    "Cadastro de Aula",
                    "Existem campos obrigatórios não preenchidos. Preencha todos os campos e tente novamente",
                    {color: this.$store.state.colors.warning , showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }

            this.cardLoading = true;
            this.newClass.courseId = this.$route.params.courseId;
            ClassService
                .create(this.newClass, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Cadastro de Aula",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.$emit("close-form"))
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao cadastrar aula.",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => {
                    this.cardLoading = false; 
                    this.showNewClassForm = false
                    this.loadClassesByCourse(this.$route.params.courseId);
                })
        }
    },

    created: function() {
        const courseId = this.$route.params.courseId;
        this.loadCourse(courseId)
        this.loadClassesByCourse(courseId)
    }
}
</script>