<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Alunos</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="showStudentList = !showStudentList">
                    {{ showStudentList ? 'novo' : 'voltar' }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <StudentList v-if="showStudentList"></StudentList>
            <StudentForm v-else @close-form="showStudentList = !showStudentList"></StudentForm>
        </v-card-text>
    </v-card>
</template>

<script>
import StudentForm from "./StudentForm";
import StudentList from "./StudentsList";

export default {
    components: { StudentForm, StudentList },

    data: () => ({
        showStudentList: true,
    })
}
</script>