<template>
    <v-app>
        <v-main>
            <div style="margin-top: 1%;">
                <v-container>
                    <v-row align="center" justify="center">
                        <v-col cols="4">
                            <div align="center" style="margin-bottom: 10px">
                                <v-img src="../assets/ABDF-13.png" style="width: 200px; height: 200px"></v-img>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="4">
                            <UserLoginForm></UserLoginForm>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-main>
    </v-app> 
</template>

<script>
import UserLoginForm from "../components/widgets/user/UserLoginForm";

export default {
    name: 'Login',

    components: { UserLoginForm },
}
</script>