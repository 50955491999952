<template>
    <v-card elevation="2" :loading="cardLoading">
        <v-card-title>
            <h4>Cadastro de Palestrante:</h4>
        </v-card-title>

        <v-card-text style="padding: 25px;">
            <v-row>
                <v-col cols="4">
                    <v-text-field label="Nome:" type="text" v-model="speaker.name"></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field label="Email:" type="email" v-model="speaker.email"></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field label="Contato:" type="text" v-model="speaker.contact"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea label="Assuntos" v-model="speaker.topics"></v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <div align="right" style="width: 100%">
                    <v-btn :color="$store.state.colors.primary"
                        dark
                        large
                        @click="createSpeaker()">
                            salvar
                    </v-btn>
                </div>
            </v-row>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import SistemMessage from "../../global/SistemMessage";
    import SpeakerService from "../../../services/SpeakerService";

    export default {
        name: "SpeakerForm",

        components: { SistemMessage },

        data: () => ({
            speaker: {
                name: undefined,
                email: undefined,
                contact: undefined,
                topic: undefined,
            },
            cardLoading: false,
        }),

        methods: {
            validateFields() {
                if(!this.speaker.name || !this.speaker.email) return false; return true;
            },

            createSpeaker() {
                if(!this.validateFields()) {
                    this.$refs.message.open(
                        "Cadastro de Palestrante", 
                        "Todos os campos são obrigatórios", 
                        {color:this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                    )
                    return false;
                }

                this.cardLoading = true;

                SpeakerService
                    .create(this.speaker, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Cadastro de Palestrante", 
                            response.data.message, 
                            {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                        ).then(() => this.$emit("close-form"))
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Error ao cadastrar Palestrante",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.cardLoading = false)
            }
        }
    }
</script>