<template>
    <v-card elevation="0">
        <v-card-title>
            <h2>Acompanhamento Financeiro</h2>
        </v-card-title>

        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <v-card elevation="2">
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="campoBusca"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                                placeholder="Buscar"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                @click="showAdvancedFilters = !showAdvancedFilters">
                                    filtro avançado
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <div align="right" style="width: 100%">
                                <v-btn 
                                    dark 
                                    fab 
                                    small
                                    :color="$store.state.colors.primary"
                                    @click="getPaymentList()">
                                        <v-icon>mdi-refresh</v-icon>
                                </v-btn> &nbsp;
                                <v-btn
                                    dark
                                    :color="$store.state.colors.primary"
                                    large
                                    @click="downloadCSV()">
                                        exportar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <br>
                    <v-data-table
                        :headers="paymentListHeader"
                        :items="paymentList"
                        hide-default-footer
                        :items-per-page="9999999999999"
                        :search="campoBusca"
                        class="elevation-1"
                        :calculate-widths="true"
                        :footer-props="{
                            showFirstLastPage: true,
                            'items-per-page-text':'Itens por Página'}">
                                <template v-slot:[`item.show`]="{ item }">
                                    <v-btn icon :color="$store.state.colors.primary" @click="showBoleto(item)"><v-icon >mdi-eye-check</v-icon></v-btn>
                                </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card-text>

        <v-dialog v-model="showAdvancedFilters" width="1000">
            <v-card>
                <v-card-title>
                    <h4>Filtro Avançado:</h4>
                    <v-spacer></v-spacer>
                    <v-icon @click="showAdvancedFilters = !showAdvancedFilters">mdi-close-thick</v-icon>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field 
                                label="Status:" 
                                type="text" 
                                v-model="filter.status">
                            </v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field 
                                label="CPF:" 
                                v-mask="'###.###.###-##'"
                                type="text" 
                                v-model="filter.cpf">
                            </v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field 
                                label="CNPJ:" 
                                v-mask="'##.###.###/####-##'"
                                type="text" 
                                v-model="filter.cnpj">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                         <v-col cols="6">
                            <v-text-field 
                                label="Data Vencimento Inicial:" 
                                type="date" 
                                v-model="filter.beginDate">
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field 
                                label="Data Vencimento Final:" 
                                type="date" 
                                v-model="filter.endDate">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <v-textarea 
                                label="Descrição"  
                                v-model="filter.description">
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <div align="right" style="width: 100%">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                @click="applyFilter()">
                                    buscar
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import FinanceService from "../../../services/FinanceService";
import SistemMessage from "../../global/SistemMessage";
import csvHandler from "../../../services/csvHandler";

export default {
    name: "ListaAcompanhamentoFinanceiro",
    components: {SistemMessage},
    data: () => ({
        showAdvancedFilters: false,
        campoBusca: undefined,
        elementsNotReady: false,
        paymentListHeader: [
            { text: 'Nome', sortable: true, value: 'name', align: 'start', width: "12%" },
            { text: 'CPF', sortable: true, value: 'cpf', align: 'start' },
            { text: 'Descrição', sortable: true, value: 'description', align: 'start', width: "38%"},
            { text: 'Valor R$', sortable: true, value: 'amount', align: 'start' },
            { text: 'Vencimento', sortable: true, value: 'dueDate', align: 'start' },
            { text: 'Status', sortable: true, value: 'payNumber', align: 'start' },
            { text: 'Visualizar', sortable: true, value: 'show', align: 'end' },
        ],
        filter: {
            status: undefined,
            cpf: undefined,
            cnpj: undefined,
            beginDate: undefined,
            endDate: undefined,
            description: undefined,
        },
        paymentList: undefined,
    }),

    methods: { 
        getPaymentList() {
            this.elementsNotReady = true;
            FinanceService
                .getList(this.$store.state.token)
                .then(response => {
                    this.paymentList = response.data.paymentList;
                    this.paymentList.forEach(item => {
                        item.dueDate = item.dueDate.split("T")[0].split("-").join("/");
                        item.amount = new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2 }).format(item.amount);
                        item.payNumber = `${item.payNumber}`.length >= 20 ? "BOLETO ABERTO" : item.payNumber;
                    })
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar lista de pagamentos",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.elementsNotReady = false)
        },

        applyFilter() {
            FinanceService
                .getByFilter(this.filter, this.$store.state.token)
                .then(response => {
                    this.paymentList = response.data.paymentList;
                    this.paymentList.forEach(item => {
                        item.dueDate = item.dueDate.split("T")[0].split("-").join("/");
                        item.amount = new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2 }).format(item.amount);
                    })
                })
                .catch(error => {
                    if(error.response.status == 404) {
                        this.$refs.message.open(
                            "Filtro Avançado",
                            error.response.data.message,
                            {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                        )
                    }
                    else {
                        this.$refs.message.open(
                            "Erro ao aplicar filtro",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    }
                })
                .finally(() => this.showAdvancedFilters = false)
        },

        showBoleto(item) {
            const paymentLink = item.link;
            const hrefLink = document.createElement('a');
            hrefLink.href = paymentLink;
            hrefLink.target = '_blank';
            hrefLink.click();
        },

        downloadCSV() {
            csvHandler.exportFinanceList(this.paymentList);
        }
    },

    created: function() {
        this.getPaymentList();
    }
}
</script>