<template>
  <v-app>
    <Menu></Menu>
    <v-main>
      <v-container fluid style="padding: 25px;">
          <v-row align="center" justify="center">
              <v-col cols="12">
                <v-img src="../assets/ABDF-03.png" style="margin-top: 10%;"></v-img>
              </v-col>
          </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu"

export default {
  name: 'Home',
  components: { Menu },
  beforeCreate: function() {
    if(!this.$store.state.user) this.$router.push("/login");
  }
}
</script>
