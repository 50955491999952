<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Associados</h2>
            
            <v-spacer></v-spacer>
            
            <v-btn 
                :color="showAssociatedsList ? $store.state.colors.primary : $store.state.colors.primary" 
                dark  
                large 
                @click="showAssociatedsList = !showAssociatedsList">
                    {{ showAssociatedsList ? "Novo" : "Voltar"}}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <AssocitesList v-if="showAssociatedsList"></AssocitesList>
            <AssociateForm v-else @close-form="showAssociatedsList = !showAssociatedsList"></AssociateForm>
        </v-card-text>
    </v-card>
</template>

<script>
import AssocitesList from "./AssociatesList";
import AssociateForm from "./AssociateForm";

export default {
    name: "AssociatesMainComponent",
    components: { AssocitesList, AssociateForm },
    data: () => ({
        showAssociatedsList: true
    })
}
</script>