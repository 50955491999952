<template>
  <router-view></router-view>
</template>

<script>

export default {
    name: 'App',

    beforeCreate: function() {
        if(this.$route.path == "/associado-externo" || this.$route.path == "/associado-externo-boleto") {
            this.$cookies.set("actualPage", this.$route.path);
            this.$router.push(this.$route.path).catch(() => {});
            return    
        }

        this.$store.state.user = this.$cookies.get("user");
        this.$store.state.token = this.$cookies.get("token");
        const actualPage = this.$cookies.get("actualPage");

        if(!this.$store.state.user || this.$store.state.user == null) {
            this.$router.push("/login").catch(() =>{});
        } else {
            this.$router.push(!actualPage ? '/home' : actualPage).catch(() =>{});
        }
    }
};
</script>