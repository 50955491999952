<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Eventos</h2>

            <v-spacer></v-spacer>

            <v-btn
                :color="showEventList ? $store.state.colors.primary : $store.state.colors.primary"
                dark
                large
                @click="showEventList = !showEventList">
                    {{ showEventList ? 'novo' : 'voltar' }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <EventList v-if="showEventList"></EventList>
            <EventForm v-else @close-form="showEventList = !showEventList"></EventForm>
        </v-card-text>
    </v-card>
</template>

<script>
    import EventForm from "./EventForm";
    import EventList from "./EventList";

    export default {
        name: "EventsMainComponent",
        
        components: { EventList, EventForm },

        data: () => ({
            showEventList: true,
        })
    }
</script>