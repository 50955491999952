import axios from 'axios';

const login = (userLogin) => {
    return axios.post(`${global.applicationApiEndpoint}/users/login`, userLogin)
}

const create = (newUser, token) => {
    return axios.post(`${global.applicationApiEndpoint}/users`, newUser, _mountAuthenticateHeader(token))
}

const getList = (token) => {
    return axios.get(`${global.applicationApiEndpoint}/users`, _mountAuthenticateHeader(token))
}

const remove = (userId, token) => {
    return axios.delete(`${global.applicationApiEndpoint}/users/${userId}`, _mountAuthenticateHeader(token))
}

const getById = (userId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/users/${userId}`, _mountAuthenticateHeader(token));
}

const update = (user, token) => {
    return axios.put(`${global.applicationApiEndpoint}/users`, user, _mountAuthenticateHeader(token));
}

const _mountAuthenticateHeader = (token) => {
    return { headers: { 'token': token } };
}


export default {
    login,
    create,
    getList,
    remove,
    getById,
    update
}