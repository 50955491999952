<template>
    <v-card elevation="2">
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <v-row>
                <v-col cols="4">
                    <v-text-field
                        v-model="findField"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        placeholder="Buscar"
                    ></v-text-field>
                </v-col>

                <v-col cols="8">
                    <div align="right" style="width: 100%">
                        <v-btn 
                            dark 
                            fab 
                            small
                            :color="$store.state.colors.primary"
                            @click="getSpeakerList()">
                                <v-icon>mdi-refresh</v-icon>
                        </v-btn> &nbsp;
                        <v-btn
                            dark
                            :color="$store.state.colors.primary"
                            large
                            @click="downloadCSV()">
                                exportar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <br>
            <v-data-table 
                :headers="speakersListHeader" 
                :items="speakersList" 
                :items-per-page="9999999999"
                hide-default-footer 
                :search="findField"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text':'Palestrantes por Página'}">

                <template v-slot:[`item.edit`]="{ item }">
                    <v-btn icon :color="$store.state.colors.primary" @click="editSpeaker(item)"><v-icon >mdi-pencil</v-icon></v-btn>
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                    <v-btn icon :color="$store.state.colors.danger" @click="deleteSpeaker(item)"><v-icon >mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import csvHandler from "../../../services/csvHandler";
    import SistemMessage from "../../global/SistemMessage";
    import SpeakerService from "../../../services/SpeakerService";

    export default {
        name: "SpeakerList",

        components: { SistemMessage },

        data: () => ({
            elementsNotReady: false,
        
            findField: undefined,

            speakersListHeader: [
                { text: 'ID', align: 'start', sortable: true, value: 'id' },
                { text: 'Nome', sortable: true, value: 'name' },
                { text: 'Email', sortable: true, value: 'email' },
                { text: 'Telefone', sortable: true, value: 'contact' },
                { text: 'Assuntos', sortable: true, value: 'topics' },
                { text: 'Editar', sortable: true, value: 'edit', align: 'end' },
                { text: 'Excluir', sortable: true, value: 'delete', align: 'end' }
            ],

            speakersList: undefined,
        }),

        methods: {
            deleteSpeaker(speaker) {
                this.$refs.message.
                    open(
                        "Exclusão de Palestrante",
                        "Tem certeza que deseja excluir este palestrante? Todos os eventos que esse palestrante está presente serão alterados.",
                        {color: this.$store.state.colors.danger, showSecondButton: true, firstButtonColor: this.$store.state.colors.danger, secondButtonColor: this.$store.state.colors.second}
                    )
                    .then(() => {
                        SpeakerService
                            .remove(speaker.id, this.$store.state.token)
                            .then(response => {
                                this.$refs.message.open(
                                    "Exclusão de Palestrante",
                                    response.data.message,
                                    {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                                ).then(() => this.getSpeakerList())
                            })
                            .catch(error => {
                                this.$refs.message.open(
                                    "Erro ao excluir palestrante",
                                    error.response.data.message,
                                    {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                                )
                            })
                    })
            },

            editSpeaker(speaker) {
                this.$cookies.set("actualPage", '/palestrante/' + speaker.id);
                this.$router.push("/palestrante/" + speaker.id);
            },

            downloadCSV() {
                csvHandler.exportSpeakerList(this.speakersList);
            },

            getSpeakerList() {
                this.elementsNotReady = true;
                SpeakerService
                    .getList(this.$store.state.token)
                    .then(response => this.speakersList = response.data.speakers)
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao buscar lista de Palestrantes", 
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.elementsNotReady = false)
            }
        },

        created: function () {
            this.getSpeakerList();
        }
    }
</script>