<template>
    <v-card elevation="2" :loading="cardLoading">
        <v-card-title>
            <h4>Cadastro de Evento:</h4>
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols='4'>
                    <v-text-field
                        type="text"
                        v-model="event.name"
                        :rules="[rules.required]"
                        label="Nome do Evento:">
                    </v-text-field>
                </v-col>
                <v-col cols='2'>
                    <v-text-field
                        type="date"
                        v-model="event.eventDate"
                        :rules="[rules.required]"
                        label="Data Inicio:">
                    </v-text-field>
                </v-col>
                <v-col cols='2'>
                    <v-text-field
                        type="date"
                        v-model="event.eventEndingDate"
                        :rules="[rules.required]"
                        label="Data Final:">
                    </v-text-field>
                </v-col>
                <v-col cols='2'>
                    <v-text-field
                        type="time"
                        v-model="event.begin"
                        label="Inicio:">
                    </v-text-field>
                </v-col>
                <v-col cols='2'>
                    <v-text-field
                        type="time"
                        v-model="event.finish"
                        label="Encerramento:">
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols='12'>
                    <v-textarea
                        label="Descrição:"
                        v-model="event.description">
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <div align="right" style="width: 100%">
                        <v-btn
                            :color="$store.state.colors.primary"
                            dark
                            large
                            @click="createNewEvent()">
                                salvar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import SistemMessage from "../../global/SistemMessage";
    import EventService from "../../../services/EventService";

    export default {
        name: "EventForm",
        components: { SistemMessage },
        data: () => ({
            event: { 
                name: undefined,
                eventDate: undefined,
                eventEndingDate: undefined,
                begin: undefined,
                finish: undefined,
                description: undefined
            },
            cardLoading: false,
            rules: {
                required: value => !!value || "Campo obrigatório"
            }
        }),

        methods: {
            validateFields() {
                if(!this.event.name || !this.event.eventDate) {
                    return false;
                }

                return true;
            },

            createNewEvent() {
                if(!this.validateFields()) {
                    this.$refs.message.open(
                        "Erro no Cadastro de Evento",
                        "Os campos 'Nome do Evento' e 'Data do Evento' são obrigatórios",
                        {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                    )
                    return false;
                }

                EventService
                    .create(this.event, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Cadastro de Evento",
                            response.data.message,
                            {
                                color: this.$store.state.colors.primary,
                                showSecondButton: false,
                                firstButtonColor: this.$store.state.colors.primary
                            }
                        ).then(() => this.$emit("close-form"))
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao cadastrar eventio",
                            error.response.data.message,
                            { 
                                color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger
                            }
                        )
                    })
            }
        }
    }
</script>