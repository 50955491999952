<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Palestrantes</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="goback()">
                    voltar
            </v-btn>
        </v-card-title>
            
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <v-card elevation="2" :loading="cardLoading">
                <v-card-title><h4>Cadastro de Palestrante:</h4></v-card-title>
                
                <v-card-text style="padding: 25px;">
                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Nome:" type="text" v-model="speaker.name"></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field label="Email:" type="email" v-model="speaker.email"></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field label="Contato:" type="text" v-model="speaker.contact"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-textarea label="Assuntos" v-model="speaker.topics"></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <div align="right" style="width: 100%">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                @click="updateSpeaker()">
                                    salvar
                            </v-btn>
                        </div>
                    </v-row>

                    <br><br>
                    <v-divider></v-divider>
                    <br>

                    <v-row>
                        <v-col cols="6">
                            <h3>Lista de Eventos que este palestrante mestrará:</h3>                            
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field 
                                v-model="findField" 
                                append-icon="mdi-magnify" 
                                single-line hide-details placeholder="Buscar">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div align="right" style="width: 100%">
                                <v-btn :color="$store.state.colors.primary"
                                    dark
                                    large
                                    @click="downloadCSV()">
                                        exportar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <br>

                    <v-data-table 
                        :headers="eventListHeader" 
                        :items="eventList" 
                        :items-per-page="5" 
                        :search="findField" 
                        class="elevation-1" 
                        :footer-props="{ showFirstLastPage: true, 'items-per-page-text':'Eventos por Página' }"> 

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon :color="$store.state.colors.danger" @click="deleteEvent(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>

            </v-card>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import SistemMessage from "../../global/SistemMessage";
    import csvHandler from "../../../services/csvHandler";
    import SpeakerService from "../../../services/SpeakerService";

    export default {
        name: "SpeakerEdit",

        components: { SistemMessage },

        data: () => ({
            speaker: {
                name: undefined,
                email: undefined, 
                contact: undefined,
                topics: undefined,
            },
            cardLoading: false,
            elementsNotReady: false,
            findField: undefined,

            eventListHeader: [
                { text: 'ID', align: 'start', sortable: true, value: 'id' },
                { text: 'Nome do Evento', sortable: true, value: 'name' },
                { text: 'Data do Evento', sortable: true, value: 'eventDate' },
                { text: 'Opções', value: 'actions' },
            ],
            
            eventList: [
                {id: '222334', name: 'Apresentação Advogados', eventDate: '15/06/2021', begin: '17:00', finish: '21:00'},
                {id: '432423', name: 'Bate papo OAB', eventDate: '10/04/2021', begin: '13:00', finish: '18:00'},
                {id: '543534', name: 'Palestra', eventDate: '25/05/2021', begin: '08:00', finish: '12:00'},
                {id: '654565', name: 'Direito Financeiro', eventDate: '05/05/2021', begin: '15:00', finish: '20:00'},
            ],
        }),

        methods: {
            goback() {
                this.$cookies.set("actualPage", "/palestrantes");
                this.$router.push("/palestrantes");
            },

            deleteEvent(event) {
                this.$refs.message.open(
                    "Exclusão de Evento",
                    "Deseja excluir este evento da lista de eventos mestrado por esse palestrante?",
                    {color: this.$store.state.colors.danger, showSecondButton: true, firstButtonColor: this.$store.state.colors.danger, secondButtonColor: this.$store.state.colors.second}
                )
                .then(() => {
                    SpeakerService
                        .removeEvent(event.id, this.$route.params.id, this.$store.state.token)
                        .then(response => {
                            this.$refs.message.open(
                                "Remoção de evento do palestrante",
                                response.data.message,
                                {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                            ).then(() => this.getSpeakerById(this.$route.params.id))
                        })
                        .catch(error => {
                            this.$refs.message.open(
                                "Erro ao excluir evento",
                                error.response.data.message,
                                {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                            )
                        })
                })
            },

            downloadCSV() {
                csvHandler.exportEventList(this.eventList);
            },

            getSpeakerById(speakerId) {
                this.elementsNotReady = true;
                SpeakerService
                    .getById(speakerId, this.$store.state.token)
                    .then(response => {
                        this.speaker = response.data.speaker;
                        this.eventList = response.data.events;
                        this.eventList.forEach(event => {
                            event.eventDate = event.eventDate.split("T")[0].split("-").reverse().join("/");
                        })
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao buscar dados do Palestrante",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.elementsNotReady = false)
            },

            updateSpeaker() {
                this.cardLoading = true;
                SpeakerService.
                    update(this.speaker, this.$route.params.id, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Atualização de Palestrante",
                            response.data.message,
                            {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                        )
                        .then(() => this.goback());
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao atualiza Palestrante",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
            }
        },

        created: function () {
           this.getSpeakerById(this.$route.params.id)
        }
    }
</script>