import axios from 'axios';

const create = (newEvent, token) => axios.post(`${global.applicationApiEndpoint}/events`, newEvent, mountHeader(token));
const getList = (token) => axios.get(`${global.applicationApiEndpoint}/events`, mountHeader(token));
const getById = (eventId, token) => axios.get(`${global.applicationApiEndpoint}/events/${eventId}`, mountHeader(token));
const update = (event, eventId, token) => axios.put(`${global.applicationApiEndpoint}/events/${eventId}`, event, mountHeader(token));
const updateSpeakers = (speakers, eventId, token) => axios.post(`${global.applicationApiEndpoint}/eventSpeaker/${eventId}`, speakers, mountHeader(token));
const remove = (eventId, token) => axios.delete(`${global.applicationApiEndpoint}/events/${eventId}`, mountHeader(token));
const getByFilter = (filter, token) => axios.post(`${global.applicationApiEndpoint}/events/filter`, filter, mountHeader(token))

const mountHeader = (token) => {
    return { headers: { 'token': token } };
}

export default {
    create,
    getList,
    getById,
    update,
    updateSpeakers,
    remove,
    getByFilter
}