<template>
  <v-app>
    <Menu></Menu>
    <v-main>
        <v-container fluid style="padding: 25px;">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <AssociatesMainComponent></AssociatesMainComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu";
import AssociatesMainComponent from "../components/widgets/associates/AssociatesMainComponent";

export default {
  name: 'Home',
  components: { Menu, AssociatesMainComponent },
  beforeCreate: function() {
    if(!this.$store.state.user) this.$router.push("/login");
  }
}

</script>
