<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Palestrantes</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="showSpeakerList = !showSpeakerList">
                    {{ showSpeakerList ? 'novo' : 'voltar' }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <SpeakerList v-if="showSpeakerList"></SpeakerList>
            <SpeakerForm v-else @close-form="showSpeakerList = !showSpeakerList"></SpeakerForm>
        </v-card-text>
    </v-card>
</template>

<script>
    import SpeakerList from "./SpeakerList";
    import SpeakerForm from "./SpeakerForm";

    export default {
        name: "SpeakerMainComponent",
        
        components: { SpeakerList, SpeakerForm },

        data: () => ({
            showSpeakerList: true,
        })
    }
</script>