const exportFile = (fileTitle, header, values) => {
    download(fileTitle, header, values);
}

const exportAssociatesList = (associatesList, categoriesList) => {
    const csvHeader = `ID;ATIVO;EXTERNO;APROVADO;PESSOA JURIDICA?;CNPJ;NOME EMPRESA;CPF;NOME ASSOCIADO;DATA DE NASCIMENTO;SEXO;CEP;ENDEREÇO;NÚMERO;COMPLEMENTO;MUNICIPIO;UF;E-MAIL PRINCIPAL;E-MAIL SECUNDARIO;OUTROS E-MAILS;TELEFONE RESIDENCIAL;TELEFONE COMERCIAL;CELULAR;PROFISSÃO;CATEGORIA;DATA ADMISSÃO;DATA DESLIGAMENTO;VALIDADE DE;VALIDADE ATÉ;NÚMERO IFA;COMENTÁRIOS\n`;

    var csvContent = "";
    
    associatesList.forEach(a => {
        a.cnpj = a.cnpj == undefined | null ? "" : a.cnpj;
        a.isExternal = a.isExternal == 1 ? "SIM" : "NÃO";
        a.active = a.active == 1 ? "SIM" : "NÃO";
        a.aproved = a.aproved == 1 ? "SIM" : "NÃO";
        a.isCompany = a.isCompany == 1 ? "SIM" : "NÃO";
        a.primaryEmail = a.primaryEmail == undefined | null ? "" : a.primaryEmail;
        a.secundaryEmail = a.secundaryEmail == undefined | null ? "" : a.secundaryEmail;
        a.companyEmail = a.companyEmail == undefined | null ? "" : a.companyEmail;
        a.companyName = a.companyName == undefined | null ? "" : a.companyName;
        a.startDate = a.startDate == undefined | null ? "" : a.startDate.split("T")[0];
        a.endDate = a.endDate == undefined | null ? "" : a.endDate.split("T")[0];
        a.validFrom = a.validFrom == undefined | null ? "" : a.validFrom.split("T")[0];
        a.validTo = a.validTo == undefined | null ? "" : a.validTo.split("T")[0];
        a.comments = a.comments == undefined | null ? "" : a.comments;
        a.birth = a.birth == undefined | null ? "" : a.birth.split("T")[0];
        a.complement = a.complement == undefined | null ? "" : a.complement
        a.category = categoriesList.find(c => {
            if(c.id == a.category) return c;
        });
        
        if(a.category == undefined) return;

        a.category = a.category.name;

        csvContent += `${a.id};${a.active};${a.isExternal};${a.aproved};${a.isCompany};${a.cnpj};${a.companyName};${a.cpf};${a.name};${a.birth};${a.gender};${a.zipcode};${a.address};${a.number};${a.complement};${a.burgh};${a.uf};${a.primaryEmail};${a.secundaryEmail};${a.companyEmail};${a.homePhone};${a.companyPhone};${a.cellphone};${a.profession};${a.category};${a.startDate};${a.endDate};${a.validFrom};${a.validTo};${a.ifaNumber};${a.comments}\n`;
    });

    download("lista_associados_", csvHeader, csvContent);
}

const exportAssociatePaymentList = (paymentList) => {
    const csvHeader = "ID;Mês;Data Vencimento;Data Pagamento\n";
    var csvContent = "";
    paymentList.forEach(element => {
        csvContent += `${element.id};${element.month};${element.spoilDate};${element.paymentDate}\n`;
    });

    download("lista_pagamentos_associado_", csvHeader, csvContent);
}

const exportSpeakerList = (speakerList) => {
    const csvHeader = "ID;Nome;Email;Telefone;Assuntos\n";
    var csvContent = "";
    speakerList.forEach(element => {
        element.phone = element.phone == undefined | null ? "" : element.phone
        element.subjects = element.subjects == undefined | null ? "" : element.subjects
        
        csvContent += `${element.id};${element.name};${element.email};${element.phone};${element.subjects}\n`
    })

    download("lista_palestrantes_", csvHeader, csvContent)
}

const exportEventList = (eventList) => {
    const csvHeader = "ID;TÍTULO;DATA INÍCIO;DATA ENCERRAMENTO;INÍCIO;FINAL;PARTICIPANTES EXTERNOS;DESCRIÇÃO\n";
    var csvContent = "";
    eventList.forEach(e => {
        e.eventDate = e.eventDate == undefined | null ? "" : e.eventDate;
        e.eventEndingDate = e.eventEndingDate == undefined | null ? "" : e.eventEndingDate;
        e.begin = e.begin == undefined | null ? "" : e.begin;
        e.finish = e.finish == undefined | null ? "" : e.finish;
        e.countExternal = e.countExternal == undefined | null ? 0 : e.countExternal;
        e.description = e.description == undefined | null ? "" : e.description.trim().replace(/(?:\r\n|\r|\n)/g, " ").split(";").join(".");
        csvContent += `${e.id};${e.name};${e.eventDate};${e.eventEndingDate};${e.begin};${e.finish};${e.countExternal};${e.description}\n`;
    });

    download("lista_eventos_" + Date.now(), csvHeader, csvContent)
}

const exportCategoriesList = (categories) => {
    const csvHeader = "ID;NOME;DESCRIÇÃO;CONTRIBUIÇÃO ANUAL\n";
    var csvContent = "";
    categories.forEach(c => {
        csvContent += `${c.id};${c.name};${c.description};${c.cost}\n`;
    });

    download("lista_categorias_", csvHeader, csvContent)
}

const exportCourseList = (courses) => {
    const csvHeader = "ID;NOME;MÓDULO;DATA INÍCIO;HORÁRIO;ANO;SEMESTRE;DESCRIÇÃO;DOMINGO;SEGUNDA;TERÇA;QUARTA;QUINTA;SEXTA;SÁBADO\n";
    var csvContent = "";
    courses.forEach(c => {
        c.sunday = c.sunday == 1 ? "SIM" : "NÃO";
        c.monday = c.monday == 1 ? "SIM" : "NÃO";
        c.tuesday = c.tuesday == 1 ? "SIM" : "NÃO";
        c.wednesday = c.wednesday == 1 ? "SIM" : "NÃO";
        c.thursday = c.thursday == 1 ? "SIM" : "NÃO";
        c.friday = c.friday == 1 ? "SIM" : "NÃO";
        c.saturday = c.saturday == 1 ? "SIM" : "NÃO";

        csvContent += `${c.id};${c.name};${c.initDate};${c.initHour};${c.year};${c.semester};${c.description};${c.sunday};${c.monday};${c.tuesday};${c.wednesday};${c.thursday};${c.friday};${c.saturday}\n`;
    });

    download("lista_turmas_", csvHeader, csvContent)
}

const exportUsersList = (users) => {
    const csvHeader = "ID;NOME;EMAIL\n";
    var csvContent = "";
    users.forEach(u => {
        csvContent += `${u.id};${u.name};${u.email}\n`;
    });

    download("lista_usuarios_", csvHeader, csvContent)
}

const expostClassList = (classes) => {
    const csvHeader = `ID;TITULO;PROFESSOR;AULA;DATA\n`;
    var csvContent = "";
    classes.forEach(element => {
        element.date = element.date == undefined | null ? "" : element.date;
        csvContent += `${element.id};${element.name};${element.teacher};${element.class};${element.date}\n`;
    });

    download("lista_aulas_", csvHeader, csvContent)
}

const exportStudentsList = (students) => {
    const csvHeader = `ID;ATIVO;NOME;CPF;EMPRESA;CNPJ;DATA DE NASCIMENTO;CEP;ENDEREÇO;NÚMERO;CIDADE;ESTADO;UF;TELEFONE;CELULAR;EMAIL PRINCIPAL;EMAIL SECUNDÁRIO;TURMA;PESSOA FÍSICA;PESSOA JURÍDICA;PAGAMENTO A VISTA;PARCELADO;PARCELAS\n`;
    var csvContent = "";
    students.forEach(s => {
        console.log(s);
        s.active = s.active == 1 ? "SIM" : "NÃO";
        s.company = s.company == undefined | null ? "" : s.company;
        s.cnpj = s.cnpj == undefined | null ? "" : s.cnpj;
        s.primaryEmail = s.primaryEmail == undefined | null ? "" : s.primaryEmail;
        s.secundaryEmail = s.secundaryEmail == undefined | null ? "" : s.secundaryEmail;
        s.birth = s.birth == undefined | null ? "" : s.birth.split("T")[0].split("-").reverse().join("/");
        s.zipcode = s.zipcode == undefined | null ? "" : s.zipcode;
        s.uf = s.uf == undefined | null ? "" : s.uf;
        s.address = s.address == undefined | null ? "" : s.address;
        s.number = s.number == undefined | null ? "" : s.number;
        s.city = s.city == undefined | null ? "" : s.city;
        s.state = s.state == undefined | null ? "" : s.state;
        s.phone = s.phone == undefined | null ? "" : s.phone;
        s.cellphone = s.cellphone == undefined | null ? "" : s.cellphone;
        s.asPerson = s.asPerson == 1 ? "SIM" : "NÃO";
        s.asCompany = s.asPerson == 0 ? "SIM" : "NÃO";
        s.paymentOnCash = s.paymentOnCash == 1 ? "SIM" : "NÃO";
        s.installments = s.installments == 1 ? "SIM" : "NÃO";
        s.numberOfInstallments = s.numberOfInstallments == undefined | null ? 1 : s.numberOfInstallments;

        csvContent += `${s.id};${s.active};${s.name};${s.cpf};${s.company};${s.cnpj};${s.birth};${s.zipcode};${s.address};${s.number};${s.city};${s.state};${s.uf};${s.phone};${s.cellphone};${s.primaryEmail};${s.secundaryEmail};${s.course};${s.asPerson};${s.asCompany};${s.paymentOnCash};${s.installments};${s.numberOfInstallments}\n`;
    });

    download("lista_participantes_", csvHeader, csvContent)
}

const exportRegisteredAssociates = (eventName, associatesList, categoriesList) => {
    const csvHeader = `NOME DO EVENTO;ID;ATIVO;EXTERNO;APROVADO;PESSOA JURÍDICA;CNPJ;EMPRESA;CPF;NOME;DATA NASCIMENTO;SEXO;CEP;ENDEREÇO;NÚMERO;COMPLEMENTO;MUNICÍPIO;UF;EMAIL PRINCIPAL;EMAIL SECUNDÁRIO;OUTROS EMAILS;TELEFONE RESIDENCIAL;TELEFONE COMERCIAL;CELULAR;PROFISSÃO;CATEGORIA;DATA ADMISSÃO;DATA DESLIGAMENTO;VALIDADE DE;VALIDADE ATÉ;NÚMERO IFA;COMENTÁRIOS\n`;

    var csvContent = "";
    
    associatesList.forEach(a => {
        a.cnpj = a.cnpj == undefined | null ? "" : a.cnpj;
        a.primaryEmail = a.primaryEmail == undefined | null ? "" : a.primaryEmail;
        a.secundaryEmail = a.secundaryEmail == undefined | null ? "" : a.secundaryEmail;
        a.companyEmail = a.companyEmail == undefined | null ? "" : a.companyEmail;
        a.companyName = a.companyName == undefined | null ? "" : a.companyName;
        a.startDate = a.startDate == undefined | null ? "" : a.startDate.split("T")[0];
        a.endDate = a.endDate == undefined | null ? "" : a.endDate.split("T")[0];
        a.validFrom = a.validFrom == undefined | null ? "" : a.validFrom.split("T")[0];
        a.validTo = a.validTo == undefined | null ? "" : a.validTo.split("T")[0];
        a.comments = a.comments == undefined | null ? "" : a.comments;
        a.birth = a.birth == undefined | null ? "" : a.birth.split("T")[0];
        a.complement = a.complement == undefined | null ? "" : a.complement
        a.isExternal = a.isExternal == 1 ? "SIM" : "NÃO";
        a.active = a.active == 1 ? "SIM" : "NÃO";
        a.aproved = a.aproved == 1 ? "SIM" : "NÃO";
        a.isCompany = a.isCompany == 1 ? "SIM" : "NÃO";

        a.category = categoriesList.find(c => {
            if(c.id == a.category) return c;
        });
        
        if(a.category == undefined) return;

        a.category = a.category.name;

        csvContent += `${eventName};${a.id};${a.active};${a.isExternal};${a.aproved};${a.isCompany};${a.cnpj};${a.companyName};${a.cpf};${a.name};${a.birth};${a.gender};${a.zipcode};${a.address};${a.number};${a.complement};${a.burgh};${a.uf};${a.primaryEmail};${a.secundaryEmail};${a.companyEmail};${a.homePhone};${a.companyPhone};${a.cellphone};${a.profession};${a.category};${a.startDate};${a.endDate};${a.validFrom};${a.validTo};${a.ifaNumber};${a.comments}\n`;
    });
    download("lista_associados_registrados_", csvHeader, csvContent);
}

const exportPresenceReport = (presenceList) => {
    const csvHeader = "NOME;TURMA;QTDE;MÓDULO\n";
    var csvContent = "";
    console.log(presenceList);
    presenceList.forEach((presence) => {
        csvContent += `${presence.studentName};${presence.courseName};${presence.presence};${presence.module}\n`
    })
    download("lista_presença_", csvHeader, csvContent);
}

const exportFinanceList = (financeList) => {
    const csvHeader = `ID BOLETO;NOME;CPF;CÓDIGO;VENCIMENTO;BOLETO;STATUS;VALOR;DESCRIÇÃO\n`;

    var csvContent = "";

    financeList.forEach((payment) => {
        payment.dueDate = payment.dueDate.split("T")[0].split("-").reverse().join("/");
        csvContent += `${payment.paymentId};${payment.name};${payment.cpf};${payment.code};${payment.dueDate};${payment.link};${payment.payNumber};${payment.amount};${payment.description}\n`;
    })

    download("lista_pagamentos_", csvHeader, csvContent);
}

const exportStudentPresenceList = (presenceList) => {
    console.log(presenceList);

    const csvHeader = `NOME;DATA;PRESENÇA;MÓDULO\n`;
    var csvContent = "";
    presenceList.forEach((presence) => {
        presence.date = presence.date.split("T")[0].split("-").reverse().join("/");
        csvContent += `${presence.name};${presence.date};${presence.presence};${presence.module}\n`
    })
    const filename = presenceList[0].name.split(" ").join("_");
    download("presença_"+ filename + "_", csvHeader, csvContent);
}

const download = (filename, csvHeader, csvContent) => {
    const csv = csvHeader + csvContent;
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,%EF%BB%BF'+encodeURI(csv);
    downloadLink.target = '_blank';
    downloadLink.download = filename + Date.now() + ".csv";
    downloadLink.click();
}


export default {
    exportAssociatesList,
    exportAssociatePaymentList,
    exportSpeakerList,
    exportEventList,
    exportCategoriesList,
    exportCourseList,
    exportUsersList,
    exportFile,
    expostClassList,
    exportStudentsList,
    exportRegisteredAssociates,
    exportPresenceReport,
    exportFinanceList,
    exportStudentPresenceList
}