import axios from 'axios';

const create = (newCourse, token) => {
    return axios.post(`${global.applicationApiEndpoint}/courses`, newCourse, _mountAuthenticateHeader(token))
}

const getList = (token) => {
    return axios.get(`${global.applicationApiEndpoint}/courses`, _mountAuthenticateHeader(token))
}

const remove = (courseId, token) => {
    return axios.delete(`${global.applicationApiEndpoint}/courses/${courseId}`, _mountAuthenticateHeader(token))
}

const getById = (courseId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/courses/${courseId}`, _mountAuthenticateHeader(token));
}

const update = (course, courseId, token) => {
    return axios.put(`${global.applicationApiEndpoint}/courses/${courseId}`, course, _mountAuthenticateHeader(token));
}

const _mountAuthenticateHeader = (token) => {
    return { headers: { 'token': token } };
}


export default {
    create,
    getList,
    remove,
    getById,
    update
}