<template>
    <v-card elevarion="10">
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <v-row>
                <v-col cols="4">
                    <v-text-field
                        v-model="findCourse"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        placeholder="Buscar"
                    ></v-text-field>
                </v-col>

                <v-col cols="8">
                    <div align="right" style="width: 100%">
                        <v-btn 
                            dark 
                            fab 
                            small
                            :color="$store.state.colors.primary"
                            @click="loadCoursesList()">
                                <v-icon>mdi-refresh</v-icon>
                        </v-btn> &nbsp;
                        <v-btn :color="$store.state.colors.primary"
                            dark
                            large
                            @click="downloadCSV()">
                                exportar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <br>
            <v-data-table 
                :headers="courseListHeader" 
                :items="courseList" 
                :items-per-page="9999999999"
                hide-default-footer 
                :search="findCourse"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text':'Turmas por Página'}">

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon :color="$store.state.colors.primary" @click="editCourse(item)">mdi-pencil</v-icon>&nbsp;&nbsp;
                </template>
            </v-data-table>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import csvHandler from "../../../services/csvHandler";
import CourseService from "../../../services/CourseService"
import SistemMessage from "../../global/SistemMessage.vue"

export default {
    components: { SistemMessage },
    data: () => ({
        findCourse: undefined,
        elementsNotReady: false,
        courseListHeader: [
            { text: 'ID', align: 'start', sortable: true, value: 'id' },
            { text: 'Turma', sortable: true, value: 'name' },
            { text: 'Módulo', sortable: true, value: 'module' },
            { text: 'Ano', sortable: true, value: 'year' },
            { text: 'Semestre', sortable: true, value: 'semester' },
            { text: 'Editar', sortable: true, value: 'actions', align: 'end'},
        ],

        courseList: [],
    }),

    methods: { 
        editCourse(course) { 
            this.$cookies.set("actualPage", '/curso/' + course.id);
            this.$router.push('/curso/' + course.id).catch(() =>{});
        },

        setStudantPresence(course) { 
            this.$cookies.set("actualPage", '/curso/' + course.id);
            this.$router.push('/curso/' + course.id).catch(() =>{});
        },

        downloadCSV() {
            csvHandler.exportCourseList(this.courseList);
        },

        loadCoursesList() {
            this.elementsNotReady = true;
            CourseService
                .getList(this.$store.state.token)
                .then(response => this.courseList = response.data.courses)
                .catch(error => {
                    if(error.response.status == 500) {
                        this.$refs.message.open(
                            "Erro ao buscar Turmas",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    }
                    
                })
                .finally(() => this.elementsNotReady = false)
        }
    },

    created: function() {
        this.loadCoursesList();
    }
}
</script>