<template>
    <div>
        <v-card elevation="2" style="padding: 25px;">
            <v-card-title>
                <h4>Cadastro de Associado:</h4>
                <v-spacer></v-spacer>
                <!-- <v-switch v-model="isExternal" label="Associado externo"></v-switch> -->
            </v-card-title>

            <v-card-text>
                <!-- define if the associate is a company or not -->
                <v-row>
                    <v-col cols="12">
                        <v-switch 
                            label="É Pessoa Juridica"
                            v-model="associate.isCompany">
                        </v-switch>
                    </v-col>
                </v-row>

                <!-- only displayed if is a company -->
                <v-row v-if="associate.isCompany">
                    <v-col cols="7">
                        <v-text-field
                            label="Nome da Empresa:"
                            v-model="associate.companyName"
                            :rules="[rules.required]">
                            type="text">
                        </v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            label="CNPJ:"
                            v-model="associate.cnpj"
                            v-mask="'##.###.###/####-##'"
                            :rules="[rules.required]"
                            type="text">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- personal infomation -->
                <v-row>
                    <!-- name -->
                    <v-col cols="5">
                        <v-text-field 
                            :label="associate.isCompany ? 'Nome do Diretor ou Sócio Responsável:' : 'Nome Completo:'" 
                            type="text" 
                            v-model="associate.name"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- cpf -->
                    <v-col cols="3" v-if="associate.isCompany">
                        <v-text-field 
                            label="CPF:" 
                            v-mask="'###.###.###-##'"
                            type="text" 
                            v-model="associate.cpf">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" v-else>
                        <v-text-field 
                            label="CPF:" 
                            type="text" 
                            v-mask="'###.###.###-##'"
                            v-model="associate.cpf"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>
                                                
                    <!-- birth -->
                    <v-col cols="2">
                        <v-text-field 
                            label="Data Nascimento:" 
                            type="date"
                            prepend-inner 
                            v-model="associate.birth"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- gender -->
                    <v-col cols="2">
                        <v-select 
                            :items="genderSelectOptions" 
                            label="Sexo:" 
                            v-model="associate.gender">
                        </v-select>
                    </v-col>
                </v-row>

                <!-- address information -->
                <v-row>
                    <!-- zipcode (it will find the address automatically) -->
                    <v-col cols="2">
                        <v-text-field 
                            label="CEP:" 
                            type="text" 
                            v-mask="'#####-###'"
                            @blur="findDataByZipCode()" 
                            v-model="associate.zipcode"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- address -->
                    <v-col cols="3">
                        <v-text-field 
                            label="Endereço:" 
                            type="text" 
                            v-model="associate.address"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- house number -->
                    <v-col cols="2">
                        <v-text-field 
                            label="Numero:" 
                            type="text" 
                            v-model="associate.number"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>
                     <!-- complement -->
                    <v-col cols="2">
                        <v-text-field 
                            label="Complemento:" 
                            type="text" 
                            v-model="associate.complement">
                        </v-text-field>
                    </v-col>

                    <!-- burgh-->
                    <v-col cols="2">
                        <v-text-field 
                            label="Municipio:" 
                            type="text" 
                            v-model="associate.burgh"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- uf -->
                    <v-col cols="1">
                        <v-text-field 
                            label="UF:" 
                            type="text" 
                            v-model="associate.uf"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- emails -->
                <v-row>
                    <!-- primary email-->
                    <v-col cols="4">
                        <v-text-field 
                            label="Email Principal:" 
                            type="text" 
                            v-model="associate.primaryEmail"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- secondary email -->
                    <v-col cols="4">
                        <v-text-field 
                            label="Email Secundário:" 
                            type="text" 
                            v-model="associate.secondaryEmail">
                        </v-text-field>
                    </v-col>

                    <!-- corporate email -->
                    <v-col cols="4">
                        <v-text-field 
                            label="CC Para:" 
                            type="text" 
                            v-model="associate.companyEmail">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- phones -->
                <v-row>
                    <!-- home phone-->
                    <v-col cols="4">
                        <v-text-field 
                            label="Telefone Residencial:" 
                            type="text" 
                            v-mask="'(##)####-####'"
                            v-model="associate.homePhone">
                        </v-text-field>
                    </v-col>

                    <!-- company phone -->
                    <v-col cols="4">
                        <v-text-field 
                            label="Telefone Comercial:"
                            v-mask="'(##)####-####'" 
                            type="text" 
                            v-model="associate.companyPhone">
                        </v-text-field>
                    </v-col>

                    <!-- cellphone -->
                    <v-col cols="4">
                        <v-text-field 
                            label="Celular:"
                            v-mask="'(##)#####-####'" 
                            type="text" 
                            v-model="associate.cellphone">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- professional information -->
                <v-row>
                    <!-- profession -->
                    <v-col cols="3">
                        <v-text-field 
                            label="Profissão:" 
                            type="text" 
                            v-model="associate.profession"
                            :rules="[rules.required]">
                        </v-text-field>
                    </v-col>

                    <!-- category -->
                    <v-col cols="3">
                        <v-select 
                            :items="categories" 
                            item-value="id"
                            item-text="name"
                            v-model="associate.category" 
                            label="Categoria:"
                            :rules="[rules.required]">
                        </v-select>
                    </v-col>
                    
                    <!-- start Date -->
                    <v-col cols="2">
                        <v-text-field 
                            label="Data Admissão:" 
                            type="date" 
                            v-model="associate.startDate">
                        </v-text-field>
                    </v-col>

                    <!-- user active -->
                    <v-col cols="2">
                        <v-checkbox 
                            :color="$store.state.colors.primary" 
                            label="Associado Ativo" 
                            v-model="associate.active">
                        </v-checkbox>
                    </v-col>

                    <!-- end Date -->
                    <v-col cols="2">
                        <v-text-field v-if="!associate.active" 
                            label="Data Desligamento:" 
                            type="date" 
                            v-model="associate.endDate">
                        </v-text-field>
                    </v-col>
                </v-row>
                
                <!-- ifa number -->
                <v-row>
                    <v-col cols="2">
                        <v-text-field 
                            label="Data de Validade De:" 
                            type="date" 
                            v-model="associate.validFrom">
                        </v-text-field>
                    </v-col>

                    <v-col cols="2">
                        <v-text-field 
                            label="Data de Validade Até:" 
                            type="date" 
                            v-model="associate.validTo">
                        </v-text-field>
                    </v-col>

                    <!-- ifa number -->
                    <v-col cols="3">
                        <v-text-field 
                            label="Numero IFA:" 
                            type="text" 
                            v-model="associate.ifaNumber">
                        </v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12">
                        <v-textarea label="Comentários:"
                            v-model="associate.comments">
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" v-if="associate.isExternal">
                        <v-btn 
                            :color="$store.state.colors.primary"
                            dark
                            large
                            v-if="!newAssociate && !isExternal && update && !associate.aproved"
                            :loading="cardLoading"
                            @click="aproveAssociate()">
                                Aprovar
                        </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <div align=right>
                            <v-btn
                                v-if="!update"
                                :color="$store.state.colors.primary"
                                dark
                                large
                                :loading="cardLoading"
                                @click="createNewAssociate()">
                                    salvar
                            </v-btn>

                            <v-btn 
                                v-else
                                :color="$store.state.colors.primary"
                                dark
                                large
                                :loading="cardLoading"
                                @click="updateAssociate()">
                                    atualizar
                            </v-btn>
                        </div>
                    </v-col>

                </v-row>
            </v-card-text>
            
            <br>

            <!-- <AssociatePaymentList v-if="!newAssociate && !isExternal && update"></AssociatePaymentList> -->
        </v-card>

        <SistemMessage ref="message"></SistemMessage>
    </div>
</template>

<script>
    import axios from "axios"
    import SistemMessage from "../../global/SistemMessage";
    // import AssociatePaymentList from "./AssociatePaymentList";
    import AssociateService from "../../../services/AssociateService";
    import CategoryService from "../../../services/CategoryService";

    export default {
        components: { SistemMessage, },
        
        data: () => ({
            newAssociate: false,
            cardLoading: false,
            isExternal: false,
            genderSelectOptions: ["Masculino", "Feminino"],
            categories: undefined,
            associate: {
                id: undefined,
                isExternal: false,
                isCompany: false,
                cnpj: undefined,
                companyName: undefined,
                cpf: undefined,
                name: undefined,
                birth: undefined,
                gender: undefined,
                zipcode: undefined,
                address: undefined,
                number: undefined,
                complement: undefined,
                burgh: undefined,
                uf: undefined,
                profession: undefined,
                category: undefined,
                startDate: undefined,
                endDate: undefined,
                ifaNumber: undefined,
                active: undefined,
                primaryEmail: undefined,
                secondaryEmail: undefined,
                companyEmail: undefined,
                homePhone: undefined,
                companyPhone: undefined,
                cellphone: undefined,
                validFrom: undefined,
                validTo: undefined,
                comments: undefined,
            },
            rules: {
                required: value => !!value || "Campo obrigatório",
            },
            update: false,
        }),
        
        methods: { 
            getAssociateById(id) {
                this.update = true;
                this.cardLoading = true;
                AssociateService
                    .getById(id, this.$store.state.token)
                    .then(response => {
                        this.associate = response.data.associate; 
                        this.associate.birth = this.associate.birth? this.associate.birth.split("T")[0] : undefined;
                        this.associate.startDate = this.associate.startDate.split("T")[0];
                        this.associate.endDate = this.associate.endDate? this.associate.endDate.split("T")[0] : undefined;
                        this.associate.validFrom = this.associate.validFrom? this.associate.validFrom.split("T")[0] : undefined;
                        this.associate.validTo = this.associate.validTo? this.associate.validTo.split("T")[0] : undefined;
                        this.cardLoading = false;
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao buscar dados do associado",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        ).then(() => this.$emit("close-form"));
                    })
            },

            findDataByZipCode() {
                const endpoint = `https://viacep.com.br/ws/${this.associate.zipcode}/json/`;
                axios
                    .get(endpoint)
                    .then(response => {
                        this.associate.address = response.data.logradouro;
                        this.associate.burgh = response.data.localidade;
                        this.associate.uf = response.data.uf;
                    })
                    .catch(error => {
                        if(error.status == 500) 
                            this.$refs.message.open(
                                "Busca de CEP", 
                                "Não foi possível se comunicar com o serviço de busca de dados por CEP", 
                                {color: this.$store.state.colors.danger}
                            );
                        else 
                            this.$refs.message.open(
                                "Busca de CEP", 
                                "CEP não encontrado", 
                                {color: this.$store.state.colors.primary}
                            );
                            console.log(error);
                    });
            },

            validateFields() {
                if(!this.associate.isCompany && !this.associate.cpf) {
                    return false;
                }

                if(
                    !this.associate.name || !this.associate.birth ||
                    !this.associate.zipcode || !this.associate.address ||
                    !this.associate.burgh || !this.associate.uf || 
                    !this.associate.profession || !this.associate.category || !this.associate.primaryEmail
                ) {
                    return false;
                } else {
                    if(this.associate.homePhone || this.associate.companyPhone || this.associate.cellphone)
                        return true;
                    else {
                        return false;
                    }
                }
            },

            createNewAssociate() {
                if(!this.validateFields()) {
                    this.$refs.message.open(
                        "Cadastro de Associado",
                        "Existem campos obrigatórios que precisam ser preenchidos. Preencha todos os campos e tente novamente",
                        {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                    )
                    return false;
                }

                this.cardLoading = true;

                AssociateService
                    .create(this.associate)
                    .then(response => {
                        this.$refs.message.open(
                            "Cadastro de Associado",
                            response.data.message,
                            { 
                                color: this.$store.state.colors.primary, 
                                showSecondButton: false,
                                firstButtonColor: this.$store.state.colors.primary
                            }
                        ).then(() => this.$emit("close-form"))
                    }) 
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao cadastrar Associado",
                            error.response.data.message,
                            {
                                color: this.$store.state.colors.danger, 
                                showSecondButton: false, 
                                firstButtonColor: this.$store.state.colors.danger
                            }
                        )
                    })
                    .finally(() => this.cardLoading = false)

            },

            updateAssociate() {
                if(!this.validateFields()) {
                     this.$refs.message.open(
                        "Atualização de Cadastro",
                        "Existem campos obrigatórios que precisam ser preenchidos. Preencha todos os campos e tente novamente",
                        {
                            color: this.$store.state.colors.warning, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.warning
                        }
                    );
                    return false;
                }

                this.cardLoading = true;

                AssociateService
                    .update(this.associate, this.associate.id, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Atualização de Associado.",
                            response.data.message,
                            {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                        )
                        .then(() => this.$emit("close-form"))
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao atualizar associado",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.cardLoading = false)
            },

            aproveAssociate() {
                this.cardLoading = true
                AssociateService
                    .aprove(this.$route.params.id, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Aprovação de de associado",
                            response.data.message,
                            {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                        ).then(() => this.$emit("close-form"));
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao aprovar associado",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    }).finally(() => this.cardLoading = false)
            },

            loadCategories() {
                CategoryService
                    .getList(this.$store.state.token)
                    .then(response => {
                        this.categories = response.data.categories;
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Busca de categorias",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
            }
        },

        created: function () {
            this.loadCategories();
            if(this.$route.params.id) {
                this.getAssociateById(this.$route.params.id);
            }
        }
    }
</script>