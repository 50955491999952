<template>
    <v-app>
        <Menu></Menu>
        <v-main>
            <v-container fluid style="padding: 25px;">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <v-card elevation="0" app> 
                            <v-card-title>
                                <h2>Categoria</h2>
                                <v-spacer></v-spacer>
                                <v-btn :color="$store.state.colors.primary"
                                    dark
                                    large
                                    @click="goback()">
                                        voltar
                                </v-btn>
                            </v-card-title>

                            <v-card-text>
                                <CategoryForm @close="goback()"></CategoryForm>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Menu from "../components/global/Menu"
import CategoryForm from "../components/widgets/categories/CategoryForm";
export default {
    components: { Menu, CategoryForm },

    data: () => ({
        categoryId: undefined,
    }),

    methods: { 
        goback() {
            this.$cookies.set("actualPage", "/categorias");
            this.$router.push("/categorias");
        }
    },

    beforeCreate: function() {
        if(!this.$store.state.user) this.$router.push("/login");
    }
}
</script>