<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Eventos</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="gobackToEventsList()">
                    voltar
            </v-btn>
        </v-card-title>

        <v-card-text>
             <v-skeleton-loader
                v-if="elementsNotReady"
                elevation="2"
                type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
            </v-skeleton-loader>

            <v-card elevation="2" v-else :loading="cardLoading">
                <v-card-title>
                    <h4>Cadastro de Evento:</h4>
                </v-card-title>

                <v-card-text style="padding: 25px;">
                    <v-row>
                        <v-col cols='4'>
                            <v-text-field
                                type="text"
                                v-model="event.name"
                                label="Nome do Evento:">
                            </v-text-field>
                        </v-col>
                        <v-col cols='2'>
                            <v-text-field
                                type="date"
                                v-model="event.eventDate"
                                label="Data Inicio:">
                            </v-text-field>
                        </v-col>
                        <v-col cols='2'>
                            <v-text-field
                                type="date"
                                v-model="event.eventEndingDate"
                                label="Data Final:">
                            </v-text-field>
                        </v-col>
                        <v-col cols='2'>
                            <v-text-field
                                type="time"
                                v-model="event.begin"
                                label="Inicio:">
                            </v-text-field>
                        </v-col>
                        <v-col cols='2'>
                            <v-text-field
                                type="time"
                                v-model="event.finish"
                                label="Encerramento:">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols='12'>
                            <v-textarea
                                label="Descrição:"
                                v-model="event.description">
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div align="right" style="width: 100%">
                                <v-btn
                                    :color="$store.state.colors.primary"
                                    dark
                                    large
                                    @click="updateEvent()">
                                        salvar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                    <br><v-divider></v-divider><br>

                    <v-card elevation="2">
                        <v-card-title>
                            <h4>Lista de Palestrantes</h4>
                        </v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="findSpeaker"
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        placeholder="Buscar"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <br>

                            <v-data-table 
                                :headers="speakersListHeader" 
                                :items="speakersList" 
                                :items-per-page="5" 
                                :search="findSpeaker"
                                class="elevation-1"
                                :footer-props="{
                                    showFirstLastPage: true,
                                    'items-per-page-text':'Palestrantes por Página'}">

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon :color="$store.state.colors.primary" @click="addSpeaker(item)">mdi-plus</v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>

                    <br>

                    <v-card elevation="2">
                        <v-card-title>
                            <h4>Lista de Palestrantes do Evento</h4>
                        </v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="findSpeaker2"
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        placeholder="Buscar"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <br>

                            <v-data-table 
                                :headers="speakersListHeader" 
                                :items="speakersEventList" 
                                :items-per-page="5" 
                                :search="findSpeaker2"
                                class="elevation-1"
                                :footer-props="{
                                    showFirstLastPage: true,
                                    'items-per-page-text':'Palestrantes por Página'}">

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon :color="$store.state.colors.danger" @click="removeSpeaker(item)">mdi-minus</v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>

                    <br>
                    <v-row>
                        <v-col cols="12">
                            <div align="right" style="width: 100%">
                                <v-btn :color="$store.state.colors.primary"
                                    dark
                                    large
                                    @click="updateEventSpeakers()">
                                        salvar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <SistemMessage ref="message"></SistemMessage>
            </v-card>
        </v-card-text>
    </v-card>
</template> 

<script>
import EventService from "../../../services/EventService";
import SistemMessage from "../../global/SistemMessage";

export default {
    name: "EventEdit",
    components: { SistemMessage },
    data: () => ({
        event: undefined,
        findSpeaker: undefined,
        findSpeaker2: undefined,
        speakersListHeader: [
            { text: 'ID', align: 'start', sortable: true, value: 'id' },
            { text: 'Nome', sortable: true, value: 'name' },
            { text: 'Email', sortable: true, value: 'email' },
            { text: 'Incluir', sortable: true, value: 'actions' }
        ],
        speakersEventListHeader: [
            { text: 'ID', align: 'start', sortable: true, value: 'id' },
            { text: 'Nome', sortable: true, value: 'name' },
            { text: 'Email', sortable: true, value: 'email' },
            { text: 'Excluir', sortable: true, value: 'actions' }
        ],
        speakersList: [],
        speakersEventList: [], 
        elementsNotReady: false,
        cardLoading: false,
    }),

    methods: {
        gobackToEventsList() {
            this.$cookies.set("actualPage", '/eventos');
            this.$router.push("/eventos");
        },

        addSpeaker(speaker) {
            this.speakersList = this.speakersList.filter(element => {
                if(speaker.id != element.id) return element;
            });

            this.speakersEventList.push(speaker);
        },

        removeSpeaker(speaker) {
            this.speakersEventList = this.speakersEventList.filter(element => {
                if(speaker.id != element.id) return element;
            });

            this.speakersList.push(speaker);
        },

        getEventById(eventId) {
            this.elementsNotReady = true
            EventService
                .getById(eventId, this.$store.state.token)
                .then(response => {
                    response.data.event.eventDate = response.data.event.eventDate?.split("T")[0];
                    response.data.event.eventEndingDate = response.data.event.eventEndingDate?.split("T")[0];
                    this.event = response.data.event;
                    this.speakersList = response.data.speakers;
                    this.speakersEventList = response.data.eventSpeakers ? response.data.eventSpeakers : [];
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscard dados do evento",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.elementsNotReady = false)
        },

        updateEvent() { 
            this.cardLoading = true;
            EventService
                .update(this.event, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Atualização de evento",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    )
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao atualizar Evento",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.cardLoading = false)
        },

        updateEventSpeakers() {
            if(this.speakersEventList.length == 0) {
                this.$refs.message.open(
                    "Adição de Palestrante",
                    "Nenhum palestrante selecionado, selecione pelo menos um Palestrante para continuar",
                    {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }

            EventService
                .updateSpeakers(this.speakersEventList, this.$route.params.id, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Associação de palestrantes",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    )
                    .then(() => this.getEventById(this.$route.params.id))
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao associar palestrantes",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        }
    },

    created: function() {
       this.getEventById(this.$route.params.id)
    }
}
</script>