import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import cookies from 'vue-cookies';
import { VueMaskDirective } from 'v-mask'

Vue.use(cookies);
Vue.directive('mask', VueMaskDirective);
Vue.$cookies.config("1H");

Vue.config.productionTip = false;

global.applicationApiEndpoint = "http://181.215.135.121:3000"

new Vue({
    router,
    store,
    vuetify,
    render: renderApplication => renderApplication(App)
}).$mount('#app')