<template>
    <v-card elevation="2" style="padding: 25px;">
        <v-card-title>
            <h4>Cadastro de Turma:</h4>
        </v-card-title>

        <v-card-text>
           <v-row>
               <v-col cols="4">
                    <v-text-field 
                        label="Nome da Turma:" 
                        type="text" 
                        v-model="course.name" 
                        :rules="[rules.required]">
                    </v-text-field>
               </v-col>
               <v-col cols="2">
                    <v-select 
                        :items="moduleList"
                        item-text="text"
                        item-value="value" 
                        label="Módulo"
                        v-model="course.module" 
                        :rules="[rules.required]">
                    </v-select>
               </v-col>
               <v-col cols="2">
                    <v-text-field 
                        label="Inicio:" 
                        type="date" 
                        v-model="course.initDate">
                    </v-text-field>
               </v-col>
               <v-col cols="2">
                    <v-text-field 
                        label="Fim:" 
                        type="date" 
                        v-model="course.endDate">
                    </v-text-field>
               </v-col>
               <v-col cols="1">
                    <v-text-field 
                        label="Ano:" 
                        type="text" 
                        v-model="course.year">
                    </v-text-field>
               </v-col>
               <v-col cols="1">
                    <v-text-field label="Semestre:" 
                        type="text" 
                        v-model="course.semester">
                    </v-text-field>
               </v-col>
           </v-row>

           <v-row>
               <v-col cols="12">
                    <v-textarea 
                        label="Descrição:" 
                        v-model="course.description">
                    </v-textarea>
               </v-col>
           </v-row>

           <v-row>
               <v-col cols="12">
                    <h4>Recorrencia das Aulas:</h4>
               </v-col>
           </v-row>

           <v-row>
               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.sunday" 
                        label="Domingo" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>

               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.monday" 
                        label="Segunda" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>
               
               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.tuesday" 
                        label="Terça" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>
               
               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.wednesday" 
                        label="Quarta" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>
           </v-row>

           <v-row>
               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.thursday" 
                        label="Quinta" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>

               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.friday" 
                        label="Sexta" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>
               
               <v-col cols="2">
                    <v-checkbox 
                        v-model="course.saturday" 
                        label="Sabado" 
                        :color="$store.state.colors.primary">
                    </v-checkbox>
               </v-col>
               
               <v-col cols="2">
                    <v-text-field 
                        label="Horario De inicio:" 
                        type="time" 
                        v-model="course.initHour">
                    </v-text-field>
               </v-col>
           </v-row>

           <v-row>
               <div align="right" style="width: 100%">
                    <v-btn 
                        :color="$store.state.colors.primary" 
                        dark 
                        large 
                        @click="createCourse()" 
                        :loading="cardLoading">
                            salvar
                    </v-btn>
               </div>
           </v-row>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import CourseService from '../../../services/CourseService';
import SistemMessage from "../../global/SistemMessage";

export default {
    name: "CourseForm",

    components: { SistemMessage },

    data: () => ({
        moduleList: [
            {value: 1, text: "Modulo 1"},
            {value: 2, text: "Modulo 2"},
            {value: 3, text: "Modulo 3"},
        ],
        cardLoading: false,
        rules: {
            required: value => !!value || "Campo obrigatório"
        },
        course: { 
            name: undefined,
            initDate: undefined,
            endDate: undefined,
            year: undefined,
            semestre: undefined,
            description: undefined,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false, 
            thursday: false,
            friday: false,
            saturday: false,
            initHour: undefined,
            module: undefined,
        }
    }),

    methods: {
        validateFields() {
            if(!this.course.name || !this.course.module) return false;
            return true;
        },

        createCourse() {
            if(!this.validateFields()){
                this.$refs.message.open(
                    "Cadastro de Turma",
                    "Existem campos obrigatórios não preenchidos. Preencha todos os campos e tente novamente",
                    {color: this.$store.state.colors.warning , showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }

            this.cardLoading = true;
            CourseService
                .create(this.course)
                .then(response => {
                    this.$refs.message.open(
                        "Cadastro de Turma",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.$emit("close-form"));
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao cadastrar turma", 
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    );
                })
                .finally(() => this.cardLoading = false);
        }
    }
}
</script>