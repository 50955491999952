import axios from 'axios';

const create = (newSpeaker, token) => axios.post(`${global.applicationApiEndpoint}/speakers`, newSpeaker, mountHeader(token));
const getList = (token) => axios.get(`${global.applicationApiEndpoint}/speakers`, mountHeader(token));
const getById = (speakerId, token) => axios.get(`${global.applicationApiEndpoint}/speakers/${speakerId}`, mountHeader(token));
const update = (speaker, speakerId, token) => axios.put(`${global.applicationApiEndpoint}/speakers/${speakerId}`, speaker, mountHeader(token));
const removeEvent = (eventId, speakerId, token) => axios.delete(`${global.applicationApiEndpoint}/speakers/${speakerId}/event/${eventId}`, mountHeader(token));
const remove = (speakerId, token) => axios.delete(`${global.applicationApiEndpoint}/speakers/${speakerId}`, mountHeader(token));

const mountHeader = (token) => {
    return { headers: { 'token': token } };
}

export default {
    create,
    getList,
    getById,
    update,
    removeEvent,
    remove
}