<template>
    <v-card elevation="2" style="padding: 25px;">
        <v-card-title>
            <h4>Busca por Boletos</h4>
            <v-spacer></v-spacer>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <h3>Informe seu CPF ou CNPJ para buscar por seus boletos</h3>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-switch v-model="isCompany" label="User CNPJ" :color="$store.state.colors.primary"></v-switch>
                </v-col>
            </v-row>

            <v-row v-if="!isCompany">
                <v-col cols="2">
                    <v-text-field 
                        label="CPF:" 
                        v-mask="'###.###.###-##'" 
                        v-model="document"
                        @keypress.enter="getBoleto()" >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn dark fab :color="$store.state.colors.primary" @click="getBoleto()" :loading="cardLoading">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="2">
                    <v-text-field 
                        label="CNPJ:" 
                        v-mask="'##.###.###/####-##'" 
                        v-model="document"
                        @keypress.enter="getBoleto()" >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn dark fab :color="$store.state.colors.primary" @click="getBoleto()" :loading="cardLoading">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="showBoletos">
                <v-col cols="12">
                    <v-card elevation="2" v-for="boleto in externarAssociateBoletoList" :key="boleto.id" class="mt-4">
                        <v-card-title>
                            <h4><strong>Codigo do boleto:</strong> {{boleto.code}}</h4>
                            <v-spacer></v-spacer>
                            <div align="right">
                                <v-btn :color="$store.state.colors.primary" dark small @click="displayBoleto(boleto)">
                                    <v-icon left>mdi-eye</v-icon>
                                    visualizar
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="pa-2">
                                <v-col cols="3">
                                    <span><strong>Valor:</strong> R$ {{boleto.amount}}</span>
                                </v-col>
                                <v-col cols="3">
                                    <span><strong>Vencimento:</strong> {{boleto.dueDate}}</span>
                                </v-col>
                                <v-col cols="3">
                                    <span><strong>Status:</strong> {{boleto.status}}</span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="pa-2">
                                <v-col cols="12">
                                    <span><strong>Codigo de Barras:</strong> {{boleto.barcode}}</span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="pa-2">
                                <v-col cols="12">
                                    <span><strong>Descritivo:</strong> {{boleto.description}}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>

        <SistemMessage ref="message" />
    </v-card>
</template>

<script>
import AssociateService from "../../../services/AssociateService";
import SistemMessage from "../../global/SistemMessage.vue";

export default {
    components: { SistemMessage },

    data: () => ({
        isCompany: false,
        document: undefined,
        showBoletos: false,
        cardLoading: false,
        externarAssociateBoletoList: undefined,
    }),

    methods: {
        getBoleto() {
            if(!this.document) {
                this.$refs.message.open(
                    "Busca por Boletos",
                    this.isCompany ? "O campo CNPJ é obrigatório" : "O campo CPF é obrigatório",
                    {color: this.$store.state.colors.warning, firstButtonColor: this.$store.state.colors.warning}
                );
                return false;
            } 

            this.showBoletos = false;
            this.cardLoading = true;

            AssociateService
                .getBoletoByAssociateDocument({document: this.document})
                .then(response => {
                    this.showBoletos = true;
                    this.externarAssociateBoletoList = response.data.boletos;
                    this.externarAssociateBoletoList.forEach(boleto => {
                        boleto.status = boleto.status == "PAID" ? "PAGO" : 
                            boleto.status == "CANCELLED" ? "CANCELADO" : "EM ABERTO";
                        boleto.dueDate = boleto.dueDate.split("T")[0].split("-").reverse().join("/");
                    });
                })
                .catch(error => {
                    if(error.response.status == 500) {
                        this.$refs.message.open(
                            "Busca por Boletos",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, firstButtonColor: this.$store.state.colors.danger}
                        );
                    } else {
                        this.$refs.message.open(
                            "Busca por boletos",
                            error.response.data.message,
                            {color: this.$store.state.colors.warning, firstButtonColor: this.$store.state.colors.warning}
                        )
                    }
                })
                .finally(() => this.cardLoading = false)
        },

        displayBoleto(item) {
            const paymentLink = item.link;
            const hrefLink = document.createElement('a');
            hrefLink.href = paymentLink;
            hrefLink.target = '_blank';
            hrefLink.click();
        }
    }
}
</script>