<template>
    <v-dialog 
        dark
        max-width="800"
        persistent
        v-model="showConsole">
            <v-card app>
                <v-card-title>
                    <h5>Application Console</h5>
                </v-card-title>
                <v-card-text>
                    {{history}}
                    <br>
                    <v-text-field
                        type="text"
                        v-model="command"
                        @keypress.enter="performCommand()">
                    </v-text-field>
                </v-card-text>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "console",
    props: ['showConsole'],
    data: () => ({
        history: "",
        command: undefined
    }),
    methods: {
        performCommand() {
            const commands = this.command.split(" ");
            switch (commands[0]) {
                case "goto":
                    this.history = `going to ${commands[1]} view\n`;
                    this.$cookies.set("actualPage", `/${commands[1]}`);
                    this.$router.push(`/${commands[1]}`).catch(() => {});
                    break;
                case "exit":
                    this.showConsole = false;
                    break;
                case "logout":
                    this.$emit("logout");
                    break;
                case "dark":
                    this.$emit("dark");
                    break;
                default:
                    this.history = `unknow command`
                    break;
            }
        }
    }
}
</script>