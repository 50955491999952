<template>
    <v-card flat app>
        <v-card-title>
            <h2>Alunos</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="goback()">
                    voltar
            </v-btn>
        </v-card-title>

        <v-card elevation="2" style="padding: 25px;">
            <v-card-title>
                <h4>Atualização de Alunos:</h4>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field 
                            label="Nome:" 
                            type="text"
                            :rules="[rules.required]" 
                            v-model="student.name">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="CPF:" 
                            v-mask="'###.###.###-##'"
                            type="text" 
                            :rules="[rules.required]"
                            v-model="student.cpf">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field 
                            label="Empresa:" 
                            type="text" 
                            v-model="student.company">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="CNPJ:" 
                            v-mask="'###.###.###/####-##'"
                            type="text" 
                            v-model="student.cnpj">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="Nascimento:" 
                            type="date" 
                            v-model="student.birth">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="2">
                        <v-text-field 
                            label="CEP:" 
                            v-mask="'#####-###'"
                            type="text" 
                            v-model="student.zipcode"
                            @blur="findDataByZipCode()">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="Endereço:" 
                            type="text" 
                            v-model="student.address">
                        </v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-text-field 
                            label="Numero:" 
                            type="text" 
                            v-model="student.number">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="Complemento:" 
                            type="text" 
                            v-model="student.complement">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="Cidade:" 
                            type="text"
                            v-model="student.city">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field 
                            label="Estado:" 
                            type="text"
                            v-model="student.state">
                        </v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-text-field 
                            label="UF:" 
                            type="text"
                            v-model="student.uf">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-text-field 
                            label="Telefone:"
                            v-mask="'(##)####-####'"
                            type="text"
                            v-model="student.phone">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field 
                            label="Celular:" 
                            v-mask="'(##)#####-####'"
                            type="text"
                            v-model="student.cellphone">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field 
                            label="Email Principal:" 
                            type="text"
                            :rules="[rules.required]"
                            v-model="student.primaryEmail">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field 
                            label="Email Secundário:" 
                            type="text"
                            v-model="student.secundaryEmail">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-select 
                            :items="courseModuleList" 
                            label="Turma:"
                            item-text="name"
                            item-value="id"
                            v-model="student.course">
                        </v-select>
                    </v-col>

                    <v-col cols="4">
                        <v-checkbox
                            label="Matricula Ativa?"
                            v-model="student.active"
                            :color="$store.state.colors.primary">
                        </v-checkbox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <h4>Opção de pagamento:</h4>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <v-select 
                            label="Nota fiscal em nome de:"
                            :items="financialReceiveOptions"
                            item-text="text"
                            item-value="value"
                            v-model="financialReceive">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="2">
                        <v-text-field 
                            type="number"
                            label="Valor:" 
                            step="0.01"
                            :color="$store.state.colors.primary"
                            :rules="[rules.required]"
                            v-model="student.value">
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-select
                            label="Tipo pagamento:"
                            :items="paymentTypes"
                            v-model="paymentType"
                            item-text="text"
                            item-value="value">
                        </v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-select 
                            v-if="student.installments"
                            label="Numero de Parcelas:" 
                            :items="numberOfParcels"
                            value="value"
                            item-text="text"
                            item-value="value"
                            v-model="student.numberOfInstallments">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-textarea 
                            v-model="student.comments"
                            label="Observações:"
                            :color="$store.state.colors.primary">
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <div align="right" style="width: 100%">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                :loading="cardLoading"
                                @click="updateStudent()">
                                    salvar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <br>
            <v-card-text>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols='4'>
                                Lista de Presença
                            </v-col>
                            <v-col cols="8">
                                <div align="right" style="width: 100%">
                                    <v-btn 
                                        dark 
                                        fab 
                                        small
                                        :color="$store.state.colors.primary"
                                        @click="loadStudent()">
                                            <v-icon>mdi-refresh</v-icon>
                                    </v-btn> &nbsp;
                                    <v-btn
                                        dark
                                        :color="$store.state.colors.primary"
                                        large
                                        @click="downloadCSV()">
                                            exportar
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table 
                            :headers="presenceListHeaders" 
                            :items="presenceList" 
                            :items-per-page="5" 
                            class="elevation-1" 
                            :footer-props="{ showFirstLastPage: true, 'items-per-page-text':'Registros por Página' }"> 
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import axios from "axios";
import SistemMessage from "../../global/SistemMessage.vue";
import StudentService from "../../../services/StudentService";
import CourseService from "../../../services/CourseService"
import csvHandler from "../../../services/csvHandler";

export default {
    components: { SistemMessage },
    data: () => ({
        rules: {
            required: value => !!value || "Campo obrigatório"
        },
        cardLoading: false,
        student: {
            id: undefined,
            active: undefined,
            name: undefined,
            cpf: undefined,
            company: undefined,
            cnpj: undefined,
            birth: undefined,
            zipcode: undefined,
            address: undefined,
            number: undefined,
            complement: undefined,
            city: undefined,
            state: undefined,
            uf: undefined,
            phone: undefined,
            cellphone: undefined,
            primaryEmail: undefined,
            secundaryEmail: undefined,
            course: undefined,
            asPerson: undefined,
            asCompany: undefined,
            paymentOnCash: undefined,
            value: undefined,
            installments: undefined,
            numberOfInstallments: undefined
        },
        courseModuleList: undefined,
        numberOfParcels: [
            {value: 1, text: '1'},
            {value: 2, text: '2'},
            {value: 3, text: '3'},
            {value: 4, text: '4'},
            {value: 5, text: '5'},
            {value: 6, text: '6'},
            {value: 7, text: '7'},
            {value: 8, text: '8'},
            {value: 9, text: '9'},
            {value: 10, text: '10'},
            {value: 11, text: '11'},
            {value: 12, text: '12'},
            {value: 13, text: '13'},
            {value: 14, text: '14'},
            {value: 15, text: '15'},
            {value: 16, text: '16'},
            {value: 17, text: '17'},
            {value: 18, text: '18'},
            {value: undefined, text: ""},
        ],
        paymentType: undefined,
        paymentTypes: [
            {value: 1, text: "A Vista"},
            {value: 2, text: "Parcelado"},
        ],
        financialReceive: undefined,
        financialReceiveOptions: [
            {value: 1, text: "Participante"},
            {value: 2, text: "Empresa"},
        ],
        presenceListHeaders: [
            { text: 'Nome', sortable: true, value: 'class' },
            { text: 'Data', sortable: true, value: 'date' },
            { text: 'Presença', sortable: true, value: 'presence' },
            { text: 'Modulo', sortable: true, value: 'name' },
        ],
        presenceList: undefined
    }),

    watch: {
        paymentType(newValue) {
            if(newValue == 1) {
                this.student.paymentOnCash = true;
                this.student.installments = false;
            } else {
                this.student.installments = true;
                this.student.paymentOnCash = false;
            }
        },

        financialReceive(newValue) {
            if(newValue == 1) {
                this.student.asPerson = true;
                this.student.asCompany = false;
            } else {
                this.student.asPerson = false;
                this.student.asCompany = true;
            }
        }
    },

    methods: { 
        goback() {
            this.$cookies.set("actualPage", "/curso");
            this.$router.push("/curso");
        },

        loadCourses() {
            CourseService
                .getList(this.$store.state.token)
                .then(response => {
                    this.courseModuleList = response.data.courses
                    this.paymentType = this.student.paymentOnCash == 1 ? 1 : 2;
                    this.financialReceive = this.student.asPerson ? 1 : 2;
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar cursos cadastrados",
                        error.response.data.message,
                        {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                    )
                })
        },

        validateFields() {
            if(!this.student.name || !this.student.cpf || !this.student.primaryEmail || !this.student.course) 
                return false;
            if(this.student.asPerson && !this.student.cpf)
                return false;
            return true;
        },

        updateStudent() {
            if(!this.validateFields()) {
                this.$refs.message.open(
                    "Atualização de Participante",
                    "Existem campos obrigatórios não preenchidos. Preencha todos os campos e tente novamente",
                    {color: this.$store.state.colors.warning , showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            } 

            this.cardLoading = true;

            StudentService
                .update(this.student, this.$route.params.id, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Atualização de Participante",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.goback())
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao cadastrar participante",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.cardLoading = false)
        },

        findDataByZipCode() {
            const endpoint = `https://viacep.com.br/ws/${this.student.zipcode}/json/`;
            axios
                .get(endpoint)
                .then(response => {
                    this.student.address = response.data.logradouro;
                    this.student.city = this.student.state = response.data.localidade;
                    this.student.uf = response.data.uf;
                })
                .catch(error => {
                    if(error.status == 500) 
                        this.$refs.message.open(
                            "Busca de CEP", 
                            "Não foi possível se comunicar com o serviço de busca de dados por CEP", 
                            {color: this.$store.state.colors.danger}
                        );
                    else 
                        this.$refs.message.open(
                            "Busca de CEP", 
                            "CEP não encontrado", 
                            {color: this.$store.state.colors.primary}
                        );
                        console.log(error);
                });
        },

        loadStudent() {
            StudentService
                .getById(this.$route.params.id, this.$store.state.token)
                .then(response => {
                    this.student = response.data.student;
                    if(this.student.birth) {
                        this.student.birth = this.student.birth.split("T")[0].split("-").reverse().join("/")
                    }
                    this.paymentType = this.student.paymentOnCash == true ? 1 : 2;
                    this.financialReceive = this.student.asPerson == true ? 2 : 1;
                    this.presenceList = response.data.presenceList;
                    this.presenceList.forEach(presence => {
                        if(presence.date) {
                            presence.date = presence.date.split("T")[0].split("-").reverse().join("/");
                        }
                    })
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar Participante",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        },

        downloadCSV() {
            csvHandler.exportStudentPresenceList(this.presenceList);
        }
    },

    created: function() {
        if(this.$route.params.id) this.loadStudent()
        this.loadCourses();
    }
}
</script>