<template>
    <v-card elevation="2" :loading="cardLoading" >
        <!-- Create a skeletion loader that shows up when users list is loading -->
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, 
                list-item-two-line, 
                list-item-two-line, 
                list-item-two-line">
        </v-skeleton-loader>

        <v-card-title v-if="!elementsNotReady">
            <h4>Cadastro de Categoria:</h4>
        </v-card-title>

        <v-card-text v-if="!elementsNotReady" style="padding: 25px;">
            <v-row>
                <v-col cols="8">
                    <v-text-field
                        type="text"
                        label="Nome:"
                        :rules="[rules.required]"
                        v-model="category.name">
                    </v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field
                        type="number"
                        label="Contribuição Anual:"
                        :rules="[rules.required]"
                        v-model="category.cost">
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea 
                        label="Descrição:"
                        :rules="[rules.required]"
                        v-model="category.description">
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <div align="right" style="width: 100%">
                    <v-btn 
                        :color="$store.state.colors.primary"
                        dark
                        large
                        @click="!update ? createNewCategory() : updateCategory()">
                            salvar
                    </v-btn>
                </div>
            </v-row>
        </v-card-text> 

        <SistemMessage ref="message"></SistemMessage>               
    </v-card>
</template>

<script>
    import SistemMessage from "../../global/SistemMessage";
    import CategoryService from "../../../services/CategoryService";

    export default {
        name: "CategoryForm",

        components: { SistemMessage },

        data: () => ({
            category: {
                name: undefined, 
                cost: undefined, 
                description: undefined
            },
            cardLoading : false,
            update: false,
            elementsNotReady: false,
            rules: {
                required: value => !!value || "Campo obrigatório"
            },
            categoryId: undefined,
        }),

        methods: {
            validateFields() {
                if(!this.category.name || !this.category.cost || !this.category.description)
                    return false;
                return true;
            },

            createNewCategory() {
                if(!this.validateFields()) {
                    this.$refs.message.open(
                        "Cadastro de Categoria",
                        "Todos os campos são obrigatórios.",
                        {
                            color: this.$store.state.colors.warning, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.warning
                        }
                    );
                    return false;
                }

                this.cardLoading = true;

                CategoryService
                    .create(this.category, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Cadastro de Categoria",
                            response.data.message,
                            {
                                color: this.$store.state.colors.primary, 
                                showSecondButton: false, 
                                firstButtonColor: this.$store.state.colors.primary
                            }
                        ).then(() => this.$emit("close"))
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao Cadastrar Cateria",
                            error.response.data.message,
                            {
                                color: this.$store.state.colors.danger, 
                                showSecondButton: false, 
                                firstButtonColor: this.$store.state.colors.danger
                            }
                        )
                    })
                    .finally(() => this.cardLoading = false);
            },

            updateCategory() {
                if(!this.validateFields()) {
                    this.$refs.message.open(
                        "Cadastro de Categoria",
                        "Todos os campos são obrigatórios.",
                        {
                            color: this.$store.state.colors.warning, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.warning
                        }
                    );
                    return false;
                }

                this.cardLoading = true;

                CategoryService
                    .update(this.category, this.$route.params.categoryId, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Atualização de categoria",
                            response.data.message,
                            {
                                color: this.$store.state.colors.primary,
                                showSecondButton: false,
                                firstButtonColor: this.$store.state.colors.primary
                            }
                        ).then(() => this.$emit("close"))
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao atualizar categoria",
                            error.response.data.message,
                            {
                                color: this.$store.state.colors.danger,
                                showSecondButton: false,
                                firstButtonColor: this.$store.state.colors.danger
                            }
                        )
                    })
                    .finally(() => this.cardLoading = false);
            },

            loadCategory(id) {
                this.elementsNotReady = true;
                CategoryService
                    .getById(id, this.$store.state.token)
                    .then(response => {
                        this.category = response.data.category;
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao buscar dados da categoria",
                            error.response.data.message,
                            { 
                                color: this.$store.state.colors.danger,
                                showSecondButton: false,
                                firstButtonColor: this.$store.state.colors.danger
                            }
                        )
                    })
                    .finally(() => this.elementsNotReady = false)
            }
        },

        created: function() {
            if(this.$route.params.categoryId) {
                this.loadCategory(this.$route.params.categoryId)
                this.update = true;
                this.categoryId = this.$route.params.categoryId
            }
        }
    }
</script>