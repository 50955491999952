<template>
    <v-card elevation="2">
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-if="!elementsNotReady">
            <v-row>
                <v-col cols="4">
                    <v-text-field 
                        v-model="findField" 
                        append-icon="mdi-magnify" 
                        single-line 
                        hide-details 
                        placeholder="Buscar">
                    </v-text-field>
                </v-col>

                <v-col cols=3>
                    <v-btn
                        dark
                        :color="$store.state.colors.primary"
                        large
                        @click="showAdvancedFilters = !showAdvancedFilters">
                            Filtro avançado
                    </v-btn>
                </v-col>

                <v-col cols="5">
                    <div align="right" style="width: 100%">
                        <v-btn 
                            dark 
                            fab 
                            small
                            :color="$store.state.colors.primary"
                            @click="loadAssociateList()">
                                <v-icon>mdi-refresh</v-icon>
                        </v-btn> &nbsp;
                        <v-btn
                            dark
                            :color="$store.state.colors.primary"
                            large
                            @click="downloadCSV()">
                                exportar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <br>
            
            <v-data-table 
                :headers="associatesListHeader" 
                :items="associatesList" 
                :items-per-page="999999999999"
                hide-default-footer 
                :search="findField" 
                class="elevation-1" 
                :footer-props="{ showFirstLastPage: true, 'items-per-page-text':'Associados por Página' }"> 
                    <template v-slot:[`item.vcf`]="{ item }">
                        <v-btn icon :color="$store.state.colors.primary" @click="downlaodVCF(item)"><v-icon >mdi-email-check</v-icon></v-btn>
                    </template>
                    <template v-slot:[`item.edit`]="{ item }">
                        <v-btn icon :color="$store.state.colors.primary" @click="editAssociate(item)"><v-icon >mdi-pencil</v-icon></v-btn>
                    </template>
                    <template v-slot:[`item.delete`]="{ item }">
                        <v-btn icon :color="$store.state.colors.danger" @click="deleteAssociate(item)"><v-icon >mdi-delete</v-icon></v-btn>
                    </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-model="showAdvancedFilters" width="1300" persistent>
            <v-card>
                <v-card-title>
                    <h4>Filtros Avançados:</h4>
                    <v-spacer></v-spacer>
                    <v-icon @click="closeAdvanceFilter()">
                        mdi-close-thick
                    </v-icon>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Nome:" type="text" v-model="filter.name"></v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-text-field label="CPF:" type="text" v-mask="'###.###.###-##'" v-model="filter.cpf"></v-text-field>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-text-field label="Nascimento de:" type="date" v-model="filter.birthFrom"></v-text-field>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-text-field label="Nascimento até:" type="date" v-model="filter.birthTo"></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="3">
                            <v-text-field label="Município:" type="text" v-model="filter.burgh"></v-text-field>
                        </v-col>

                        <v-col cols="1">
                            <v-text-field label="UF:" type="text" v-model="filter.uf"></v-text-field>
                        </v-col>
                        
                        <v-col cols="2">
                            <v-select label="Sexo:" :items="genderList" v-model="filter.gender"></v-select>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field label="Profissão:" v-model="filter.profession"></v-text-field>
                        </v-col>

                        <v-col cols="2">
                            <v-select :items="categories" item-value="id" item-text="name" v-model="filter.category" label="Categoria:"></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">
                            <v-text-field label="Data Admissão (Inicial)" type="date" v-model="filter.startDateFrom"></v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-text-field label="Data Admissão (Final)" type="date" v-model="filter.startDateTo"></v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-text-field label="Data Desligamento (Inicial)" type="date" v-model="filter.endDateFrom"></v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-text-field label="Data Desligamento (Final)" type="date" v-model="filter.endDateTo"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-text-field type="date" label="Validade De:" v-model="filter.validFrom"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field type="date" label="Validade Até:" v-model="filter.validTo"></v-text-field>
                        </v-col>

                        <v-col cols="2"></v-col>

                        <v-col cols="2">
                            <v-checkbox label="Ativo" v-model="filter.active"></v-checkbox>
                        </v-col>

                        <v-col cols="2">
                            <v-checkbox label="Inativo" v-model="filter.inactive"></v-checkbox>
                        </v-col>

                        <v-col cols="2">
                            <v-checkbox label="Não aprovado" v-model="filter.notAproved"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-textarea v-model="filter.comments" label="Comentários"></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <div align="right" style="width: 100%">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                @click="applyFilter()">
                                    buscar
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import csvHandler from "../../../services/csvHandler";
    import SistemMessage from "../../global/SistemMessage";
    import AssociateService from "../../../services/AssociateService"
    import CategoryService from "../../../services/CategoryService"
    import vcfHandler from "../../../services/vcfHandler";
    
    export default {    
        
        name: "Associated", 
        

        components: { SistemMessage },

        data: () => ({
            elementsNotReady: true,
            findField: undefined,
            showAdvancedFilters: false,
            genderList: ['Masculino', 'Feminino'],
            statusList: ['Nenhum', 'Inadimplente'],
            professionList: ['Advogado', 'Programador', 'Escrivão', 'Servidor Publico', 'Analista de Sistemas', 'Farmaceutico', 'Médico'],
            associatesListHeader: [
                { text: 'Nome', sortable: true, value: 'name', align: "start", width: "10px"},
                { text: 'Email', sortable: true, value: 'primaryEmail', width: "10px" },
                { text: 'Telefone', sortable: true, value: 'cellphone', width: "10px" },
                { text: 'Ativo', sortable: true, value: 'active', width: "10px" }, 
                { text: 'VCF', sortable: true, value: 'vcf', width: "10px" },
                { text: 'Editar', sortable: true, value: 'edit', width: "10px" }, 
                { text: 'Remover', sortable: true, value: 'delete', width: "10px" }, 
            ],
            associatesList: undefined,
            categories: undefined,
            filter: { 
                name:undefined,
                cpf: undefined,
                birthFrom: undefined,
                birthTo: undefined,
                burg: undefined,
                uf: undefined,
                gender: undefined,
                status: undefined,
                profession: undefined,
                startDateFrom: undefined,
                startDateTo: undefined,
                finalDateFrom: undefined,
                finalDateTo: undefined,
                validFrom: undefined,
                validTo: undefined,
                active: false,
                inactive: false,
                notAproved: false,
                category: undefined,
            }
        }),

        methods: { 
            deleteAssociate(associate) {
                this.$refs.message
                    .open(
                        "Exclusão de Associado", 
                        `Tem certeza que deseja excluir este associado?\nEssa ação é irreversivel e todos os dados com relação a esse associado serão excluidos!`,
                        {color: this.$store.state.colors.danger, showSecondButton: true, firstButtonColor: this.$store.state.colors.danger}
                    )
                    .then(() => {
                        AssociateService.remove(associate.id, this.$store.state.token)
                        .then(response => {
                            this.$refs.message.open(
                                "Remoção de Associado",
                                response.data.message,
                                {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                            )
                        })
                    })
                    .catch((error) => {
                        this.$refs.message.open(
                            "Erro ao remover Associado",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.loadAssociateList())             
            },

            editAssociate(item) {
                this.$cookies.set("actualPage", "/associados/" + item.id)
                this.$router.push("/associados/" + item.id)
            },

            downloadCSV() { 
                csvHandler.exportAssociatesList(this.associatesList, this.categories);
            },

            loadAssociateList() { 
                this.elementsNotReady = true;
                AssociateService
                    .getList(this.$store.state.token)
                    .then(response => {
                        this.associatesList = response.data.associates;
                        this.associatesList.forEach(associate => {
                            associate.active = associate.active == 1 ? "SIM" : "NÃO";
                            if(associate.isCompany) associate.name = associate.companyName;
                        })
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao buscar lista",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.elementsNotReady = false);
            },

            applyFilter() {
                this.elementsNotReady = true;
                AssociateService.
                    findByFilter(this.filter, this.$store.state.token)
                    .then(response => {
                        if(response.data.associates.length <= 0) {
                            this.$refs.message.open(
                                "Busca não retornou nenhum resultado",
                                "O filtro aplicado não retornou nenhum resultado, verifique o filtro aplicado e tente novamente",
                                {color: this.$store.state.colors.warning , showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                            )
                        }
                        this.associatesList = response.data.associates
                        this.associatesList.forEach(associate => {
                            if(associate.isCompany) associate.name = associate.companyName;
                        });
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Busca não encontrou dados",
                            error.response.data.message,
                            {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                        )
                    }).finally(() => {
                        this.elementsNotReady = false;
                        this.cleanFilters();
                    });
                this.showAdvancedFilters = false;
            },

            closeAdvanceFilter() {
                this.showAdvancedFilters = false;
                this.cleanFilters();
            },

            cleanFilters() {
                this.filter.name = undefined;
                this.filter.cpf =  undefined;
                this.filter.birthFrom =  undefined;
                this.filter.birthTo =  undefined;
                this.filter.burg =  undefined;
                this.filter.uf =  undefined;
                this.filter.gender =  undefined;
                this.filter.status =  undefined;
                this.filter.profession =  undefined;
                this.filter.startDateFrom =  undefined;
                this.filter.startDateTo =  undefined;
                this.filter.finalDateFrom =  undefined;
                this.filter.finalDateTo =  undefined;
                this.filter.validFrom =  undefined;
                this.filter.validTo =  undefined;
                this.filter.active =  false;
                this.filter.inactive =  false;
                this.filter.notAproved =  false;
                this.filter.category =  undefined;
            },

            loadCategories() {
                CategoryService
                    .getList(this.$store.state.token)
                    .then(response => {
                        this.categories = response.data.categories;
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Busca de categorias",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
            },

            downlaodVCF(associate) {
                vcfHandler.generateVCF(associate);
            }
        },

        created: function() {
            this.loadAssociateList();
            this.loadCategories();
        }
    }
</script>