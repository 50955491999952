<template>
    <v-app>
    <Menu></Menu>
    <v-main>
        <v-container fluid style="padding: 25px;">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <v-card elevation="0" app>
                        <v-card-title>
                            <h2>Meu Perfil</h2>
                        </v-card-title>
                        <v-card-text>
                            <NewUserForm></NewUserForm>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu"
import NewUserForm from "../components/widgets/user/NewUserForm";

export default {
    name: "Profile",
    components: { Menu, NewUserForm },
    data: () => ({}),
    beforeCreate: function() {
        if(!this.$store.state.user) this.$router.push("/login");
    }
}
</script>