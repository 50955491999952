<template>
    <div>
        <v-app-bar app :color="$store.state.colors.primary" dark dense>
            <v-app-bar-nav-icon @click="showLateralMenu = !showLateralMenu"></v-app-bar-nav-icon>
            <v-toolbar-title>
                Associação Brasileira de Direito Financeiro
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="changeTheme()">mdi-invert-colors</v-icon>
        </v-app-bar>

        <!-- lateral menu ==> aways starts open -->
        <v-navigation-drawer 
            v-model="showLateralMenu" 
            :color="$store.state.colors.primary" 
            dark 
            app>
            <v-list-item @click="goto('/usuarios/' + $store.state.user.id)">
                <v-list-item-icon>
                <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
            </v-list-item>
            
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item v-for="option in optionsList" :key="option.label" link @click="goto(option.path)">
                        <v-list-item-icon>
                        <v-icon>{{ option.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ option.label }}</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="logout()">
                        <v-list-item-icon>
                        <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <Console :showConsole="showConsole" @logout="logout()" @dark="changeTheme()"></Console>
    </div>
</template>

<script>
import Console from "./Console.vue";
export default {
    name: 'Home',

    components: { Console },

    data: () => ({
        showLateralMenu: true,
        showConsole: false,
        optionsList: [
            {label: "Inicio", icon: "mdi-home", path: "/home"},
            {label: "Associados", icon: "mdi-account-box", path: "/associados"},
            {label: "Categorias", icon: "mdi-clipboard-list", path: "/categorias"},
            {label: "Controle de Curso PJT", icon: "mdi-book-open-variant", path: "/curso"},
            {label: "Eventos", icon: "mdi-seat", path: "/eventos"},
            {label: "Palestrantes", icon: "mdi-microphone-variant", path: '/palestrantes'},
            {label: "Financeiro", icon: "mdi-piggy-bank", path: "/financeiro"},
            {label: "Usuários", icon: "mdi-account-group", path: "/usuarios"},
        ]
    }),

    methods: {
        goto(path) {
            this.$cookies.set("actualPage", path);
            this.$router.push(path).catch(() =>{});
        },

        logout() {
            this.$vuetify.theme.dark = false;
            this.$store.state.user = undefined;
            this.$cookies.remove('user');
            this.$cookies.remove('token');
            this.$cookies.remove('actualPage');
            this.$router.push("/login").catch(() =>{});
        },

        changeTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$store.state.colors.primary = this.$vuetify.theme.dark ? "blue darken-4" : "#081951"
        },
    },

    created: function () {
        document.addEventListener("keypress", function(e) {
            if(e.code == "NumpadSubtract") {
                this.showConsole = !this.showConsole
            }
        })
    }
    
}
</script>