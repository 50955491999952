<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Aulas</h2>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="goback()">
                    voltar
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-card elevation="2" app :loading="cardLoading">
                <v-card-title>
                    <h4>Atualização de Aula</h4>  
                </v-card-title>

                <v-card-text style="padding: 25px;">
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Tema:" v-model="newClass.name"></v-text-field>
                        </v-col>

                        <v-col cols="5">
                            <v-text-field label="Aula:" v-model="newClass.class"></v-text-field>
                        </v-col>

                        <v-col cols="2">
                            <v-select label="Modulo:" :items="modules" item-text="text" item-value="value" v-model="newClass.module"></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Professor:" v-model="newClass.teacher"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div align="right" style="width: 100%">
                                <v-btn :color="$store.state.colors.primary"
                                    dark
                                    large
                                    :loading="cardLoading"
                                    @click="updateClass()">
                                        salvar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import SistemMessage from "../../global/SistemMessage";
import ClassService from "../../../services/ClassService";

export default {
    components: {SistemMessage},
    data: () => ({
        cardLoading: false,
        newClass: undefined,
        modules: [
            {value: 1, text: "Modulo 1"},
            {value: 2, text: "Modulo 2"},
            {value: 3, text: "Modulo 3"}
        ],
    }),
    methods: {
        validateFields() {
            if(this.newClass.name || this.newClass.teacher || this.newClass.class || this.newClass.module) return true;
            return false;
        },

        updateClass() {
            if(!this.validateFields()) {
                this.$refs.message.open(
                    "Atualização de Aula",
                    "Existem campos obrigatórios não preenchidos. Preencha todos os campos e tente novamente",
                    {color: this.$store.state.colors.warning , showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }
            this.cardLoading = true;
            ClassService
                .update(this.newClass, this.$route.params.id, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Atualização de Aula",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.goback());
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao atualizar aula",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.cardLoading = false);
        },

        loadClass() {
            ClassService
                .getById(this.$route.params.id, this.$store.state.token)
                .then(response => {
                    this.newClass = response.data.class;
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar aula",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
        },

        goback() {
            this.$cookies.set("actualPage", "/curso");
            this.$router.push("/curso");
        }
    },

    created: function() {
        this.loadClass();
    }
}
</script>