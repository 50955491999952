<template>
    <v-dialog 
        persistent
        v-model="dialog" 
        :max-width="options.width" 
        :style="{ zIndex: options.zIndex }" 
        @keydown.esc="cancel">

        <v-card>
            <v-toolbar 
                dark 
                :color="options.color" 
                dense 
                flat>

                <v-toolbar-title class="white--text">
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text v-show="!!message" class="pa-4">
                {{ message }}
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn 
                    dark 
                    :color="options.firstButtonColor" 
                    @click.native="agree" 
                    v-if="options.showFirstButton">
                        {{ options.firstButtonLabel }}
                </v-btn>

                <v-btn 
                    dark 
                    :color="options.secondButtonColor"
                    @click.native="cancel" 
                    v-if="options.showSecondButton">
                        {{ options.secondButtonLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
    export default {
    data: () => ({
        userConfig: undefined,
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            color: undefined,
            width: 500,
            zIndex: 200,
            firstButtonColor: undefined,
            secondButtonColor: undefined,
            showFirstButton: true,
            showSecondButton: false,
            firstButtonLabel: "Ok",
            secondButtonLabel: "Cancelar",
        }
    }),

    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            })
        },

        agree() {
            this.resolve(true)
            this.dialog = false
        },

        cancel() {
            this.reject(true)
            this.dialog = false
        }
    },

    created: function() {
        this.options.color = this.$store.state.colors.primary;
        this.options.firstButtonColor = this.$store.state.colors.primary;
        this.options.secondButtonColor = this.$store.state.colors.second;
    }
}
</script>