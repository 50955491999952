<template>
    <v-card elevation="2">
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <v-row>
                <v-col cols="4">
                    <v-text-field
                        v-model="findField"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        placeholder="Buscar"
                    ></v-text-field>
                </v-col>

                <v-col cols="8">
                    <div align="right" style="width: 100%">
                        <v-btn 
                            dark 
                            fab 
                            small
                            :color="$store.state.colors.primary"
                            @click="getCategoryList()">
                                <v-icon>mdi-refresh</v-icon>
                        </v-btn> &nbsp;
                        <v-btn :color="$store.state.colors.primary"
                            dark
                            large
                            @click="downloadCSV()">
                                exportar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <br>
            <v-data-table 
                :headers="categoriesListHeader" 
                :items="categoriesList" 
                :items-per-page="999999999"
                hide-default-footer 
                :search="findField"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text':'Categorias por Página'}">

                <template v-slot:[`item.edit`]="{ item }">
                    <v-btn icon :color="$store.state.colors.primary" @click="editCategory(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card-text>                
    </v-card>
</template>

<script>
import csvHandler from "../../../services/csvHandler";
import CategoryService from "../../../services/CategoryService"

export default {
    data: () => ({
        findField: undefined,
        categoriesListHeader: [
            { text: "Id", align: 'start', sortable: true, value: 'id' },
            { text: "Titulo", sortable: true, align: 'start', value: 'name' },
            { text: "Descrição", sortable: true, align: 'start', value: 'description' },
            { text: "Contribuição Anual", sortable: true, align: 'start', value: 'cost' },
            { text: "Editar", value: 'edit', align: 'end'},
        ],
        categoriesList: undefined,
        elementsNotReady: true
    }),

    methods: {
        downloadCSV() {
            csvHandler.exportCategoriesList(this.categoriesList)
        },

        getCategoryList() {
            this.elementsNotReady = true;
            CategoryService
                .getList(this.$store.state.token)
                .then(response => {
                    this.categoriesList = response.data.categories;
                    this.categoriesList.forEach(element => {
                        element.cost = "R$ " + element.cost;
                    })
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao listar Categorias",
                        error.response.data.message,
                        {
                            color: this.$store.state.colors.danger,
                            showSecondButton: false,
                            secondButtonColor: this.$store.state.colors.danger
                        }
                    )
                })
                .finally(() => this.elementsNotReady = false)
        },

        editCategory(category) {
            this.$cookies.set("actualPage", "/categoria/" + category.id)
            this.$router.push("/categoria/" + category.id);
        }
    },

    created: function() {
        this.getCategoryList();
    }
}
</script>