<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Eventos</h2>

            <v-spacer></v-spacer>

            <v-btn :color="$store.state.colors.primary"
                dark
                large
                @click="gobackToEventsList()">
                    voltar
            </v-btn>
        </v-card-title>

        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <v-card elevation="2">
                <v-card-text>
                    <v-card elevation="2">
                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <b>Evento:</b> <span>{{ selectedEvent.name }}</span>
                                </v-col>

                                <v-col cols="4">
                                    <b>Data:</b> <span>{{ selectedEvent.eventDate.split("T")[0].split("-").reverse().join("/") }}</span>
                                </v-col>
                                
                                <v-col cols="4">
                                    <b>Inicio:</b> <span>{{ selectedEvent.begin }}</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <b>Encerramento:</b> <span>{{ selectedEvent.finish }}</span>
                                </v-col>

                                <v-col cols="4">
                                    <b>Participantes Associados:</b> <span>{{ registeredAssociatesList.length }}</span>
                                </v-col>

                                <v-col cols="2">
                                    <v-text-field type="number" v-model="selectedEvent.countExternal" label="Total de Participantes:"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card> <br>

                    <v-card elevation="2">
                        <v-card-title>
                            <h4>Lista de Associados Disponíveis:</h4>
                            <v-spacer></v-spacer>
                            <!-- <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                @click="showExternalAssociateFormDialog = true;">
                                    Adicionar Externo
                            </v-btn> -->
                        </v-card-title>

                        <v-dialog v-model="showExternalAssociateFormDialog" width="800">
                            <v-card flat>
                                <v-card-title>
                                    <h4>Adicionar participante externo:</h4>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="showExternalAssociateFormDialog = false;">
                                        <v-icon>mdi-close-thick</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field label="Nome:"></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field label="Telefone:"></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field label="Nascimento:" type="date"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field label="Email:"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div align="right" style="width: 100%">
                                                <v-btn :color="$store.state.colors.primary"
                                                    dark
                                                    large
                                                    @click="showExternalAssociateFormDialog = false;">
                                                        adicionar
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-dialog>

                        <v-skeleton-loader
                            v-if="elementsNotReady"
                            elevation="2"
                            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
                        </v-skeleton-loader>

                        <v-card-text v-if="!elementsNotReady">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field 
                                        v-model="findField" 
                                        append-icon="mdi-magnify" 
                                        single-line hide-details placeholder="Buscar">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <br>
                            
                            <v-data-table 
                                :headers="availableAssociatesListHeader" 
                                :items="availableAssociatesList" 
                                :items-per-page="5" 
                                :search="findField" 
                                class="elevation-1" 
                                :footer-props="{ showFirstLastPage: true, 'items-per-page-text':'Associados Disponíveis por Página' }"> 

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon :color="$store.state.colors.primary" @click="addAssociateToParticipantesList(item)">mdi-plus</v-icon>&nbsp;
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card> <br>

                    <v-card elevation="2">
                        <v-card-title>
                            <h4>Lista de Associados Registrados para o Evento:</h4>
                        </v-card-title>

                        <v-skeleton-loader
                            v-if="elementsNotReady"
                            elevation="2"
                            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
                        </v-skeleton-loader>

                        <v-card-text v-if="!elementsNotReady">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field 
                                        v-model="findField2" 
                                        append-icon="mdi-magnify" 
                                        single-line hide-details placeholder="Buscar">
                                    </v-text-field>
                                </v-col>

                                <v-col cols="6" align="right">
                                    <v-btn 
                                        :color="$store.state.colors.primary"
                                        dark
                                        large
                                        @click="exportRegisteredAssociates()">
                                            exportar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <br>
                            
                            <v-data-table 
                                :headers="registeredAssociatesListHeader" 
                                :items="registeredAssociatesList" 
                                :items-per-page="5" 
                                :search="findField2" 
                                class="elevation-1" 
                                :footer-props="{ showFirstLastPage: true, 'items-per-page-text':'Associados Registrados por Página' }"> 

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon :color="$store.state.colors.danger" @click="deleteAssociate(item)">mdi-minus</v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>

                    <br>

                    <div align="right" style="width: 100%">
                        <v-btn
                            :color="$store.state.colors.primary"
                            dark
                            large
                            @click="updateEventAssociatesList()">
                                salvar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-card-text>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import SistemMessage from "../../global/SistemMessage";
    import EventService from "../../../services/EventService";
    import AssociateService from '../../../services/AssociateService';
    import csvHandler from "../../../services/csvHandler";
    import categoryService from "../../../services/CategoryService";

    export default {
        name: "EventAddAssociates",

        components: { SistemMessage },

        data: () => ({
            categoriesList: undefined,
            selectedEvent: undefined,
            externalParticipants: 0,
            showExternalAssociateFormDialog: false,
            elementsNotReady: false,
            findField: undefined,
            findField2: undefined,
            availableAssociatesListHeader: [
                { text: 'ID', align: 'start', sortable: true, value: 'id' },
                { text: 'Nome', sortable: true, value: 'name' },
                { text: 'Profissão', sortable: true, value: 'profession' },
                { text: 'Nascimento', sortable: true, value: 'birth' },
                { text: 'Sexo', sortable: true, value: 'gender' },
                { text: 'Incluir', sortable: true, value: 'actions' }
            ],

            availableAssociatesList: undefined,

            registeredAssociatesListHeader: [
                { text: 'ID', align: 'start', sortable: true, value: 'id' },
                { text: 'Nome', sortable: true, value: 'name' },
                { text: 'Profissão', sortable: true, value: 'profession' },
                { text: 'Nascimento', sortable: true, value: 'birth' },
                { text: 'Sexo', sortable: true, value: 'gender' },
                { text: 'Excluir', sortable: true, value: 'actions' }
            ],

            registeredAssociatesList: undefined,
        }),

        methods: {
            gobackToEventsList() {
                this.$cookies.set("actualPage", '/eventos');
                this.$router.push("/eventos");
            },

            addAssociateToParticipantesList(associate) {
                this.availableAssociatesList = this.availableAssociatesList.filter(element => {
                    if(associate.id != element.id) return element
                });

                this.registeredAssociatesList.push(associate);
                this.externalParticipants = 0;
                this.registeredAssociatesList.forEach(element => {
                    if(element.isExternal) this.externalParticipants++
                });
            },

            deleteAssociate(associate) {
                this.registeredAssociatesList = this.registeredAssociatesList.filter(element => {
                    if(associate.id != element.id) return element;
                });

                this.availableAssociatesList.push(associate);
                this.externalParticipants = 0;
                this.registeredAssociatesList.forEach(element => {
                    if(element.isExternal) this.externalParticipants++
                });
            },

            save() {
                this.$refs.message.open(
                    "Registro de Associado ao Evento",
                    "Todos os vinculos foram salvos com sucesso!",
                    {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                ).then(() => {
                    this.$cookies.set("actualPage", "/eventos")
                    this.$router.push("/eventos")
                })
            },

            getEventById(eventId) {
                this.elementsNotReady = true
                EventService
                    .getById(eventId, this.$store.state.token)
                    .then(response => {
                        this.selectedEvent = response.data.event;
                        this.availableAssociatesList = response.data.associates
                        this.availableAssociatesList.forEach(element => {
                            element.birth = element.birth.split("T")[0].split("-").reverse().join("/")
                            element.active = element.active == 1 ? "SIM" : "NÃO";
                            if(element.isCompany) element.name = element.companyName;
                        });
                        this.registeredAssociatesList = response.data.eventAssociates
                        this.registeredAssociatesList.forEach(element => {
                            element.birth = element.birth.split("T")[0].split("-").reverse().join("/")
                            if(element.isExternal) this.externalParticipants++;
                            element.active = element.active == 1 ? "SIM" : "NÃO";
                            if(element.isCompany) element.name = element.companyName;
                        });
                        this.elementsNotReady = false;
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao buscard dados do evento",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
            },

            updateEventAssociatesList() {
                this.elementsNotReady = true;
                AssociateService
                    .updateRegisteredAssociatesList(this.registeredAssociatesList, this.$route.params.eventId, this.selectedEvent, this.$store.state.token)
                    .then(response => {
                        this.$refs.message.open(
                            "Registro de Associado ao Evento",
                            response.data.message,
                            {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                        ).then(() => this.$emit("close-form"));
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Erro ao registrar associados",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    })
                    .finally(() => this.elementsNotReady = false)
            },

            exportRegisteredAssociates() {
                categoryService.getList(this.$store.state.token).then(response => this.categoriesList = response.data.categories);
                csvHandler.exportRegisteredAssociates(this.selectedEvent.name, this.registeredAssociatesList, this.categoriesList);
            }
        },

        created: function() {
            this.getEventById(this.$route.params.eventId)
        }
    }
</script>