<template>
    <v-card elevation="0">
        <v-card-title>
            <h2>Usuários</h2>

            <v-spacer></v-spacer>

            <v-btn 
                :color="showUsersList ? $store.state.colors.primary : $store.state.colors.primary" 
                dark 
                large
                @click="showUsersList = !showUsersList">
                    {{ showUsersList ? "novo" : "voltar" }}</v-btn> 
        </v-card-title>

        <v-card-text>
            <UserList v-if="showUsersList"></UserList>
            <NewUserForm v-else @close-form="showUsersList = !showUsersList"></NewUserForm>
        </v-card-text>
    </v-card>
</template>

<script>
    import UserList from './UsersList';
    import NewUserForm from './NewUserForm';

    export default {
        name: "UserMainComponent",
        
        components: { UserList, NewUserForm },

        data: () => ({
            showUsersList: true
        })
    }
</script>