<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Turmas</h2>
            
            <v-spacer></v-spacer>
            
            <v-btn :color="$store.state.colors.primary" dark large @click="showCourseList = !showCourseList">
                {{ showCourseList ? 'novo' : 'voltar' }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <CourseList v-if="showCourseList"></CourseList>
            <CourseForm v-else @close-form="showCourseList = !showCourseList"></CourseForm>
        </v-card-text>
    </v-card>
</template>

<script>
import CourseList from "./CourseList";
import CourseForm from "./CourseForm";

export default {
    name: "CourseMainComponent",
    
    components: { CourseList, CourseForm },

    data: () => ({
        showCourseList: true,
    }),
}
</script>t