<template>
    <v-card elevation="2">
       <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-if="!elementsNotReady">
            <v-row>
                <v-col cols="4">
                    <v-text-field 
                        v-model="findField" 
                        append-icon="mdi-magnify" 
                        single-line 
                        hide-details 
                        placeholder="Buscar">
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-btn :color="$store.state.colors.primary"
                        dark
                        large
                        @click="showAdvancedFilters = !showAdvancedFilters">
                            filtro avançado
                    </v-btn>
                </v-col>

                <v-col cols="4" align="right">
                    <v-btn 
                        dark 
                        fab 
                        small
                        :color="$store.state.colors.primary"
                        @click="loadEventList()">
                            <v-icon>mdi-refresh</v-icon>
                    </v-btn> &nbsp;
                     <v-btn :color="$store.state.colors.primary"
                        dark
                        large
                        @click="exportEventList()">
                            exportar
                    </v-btn>
                </v-col>
            </v-row>
            <br>
            
            <v-data-table 
                :headers="eventListHeader" 
                :items="eventList" 
                :items-per-page="9999999999"
                hide-default-footer 
                :search="findField" 
                class="elevation-1" 
                :footer-props="{ showFirstLastPage: true, 'items-per-page-text':'Eventos por Página' }"> 
                    <template v-slot:[`item.edit`]="{ item }">
                        <v-btn icon :color="$store.state.colors.primary" @click="editEvent(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <template v-slot:[`item.add`]="{ item }">
                        <v-btn icon :color="$store.state.colors.primary" @click="addAssociate(item)"><v-icon>mdi-account-plus</v-icon></v-btn>
                    </template>
                    <template v-slot:[`item.delete`]="{ item }">
                        <v-btn icon :color="$store.state.colors.danger" @click="deleteEvent(item)"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-model="showAdvancedFilters" width="1000">
            <v-card>
                <v-card-title>
                    <h4>Filtro Avançado:</h4>
                    <v-spacer></v-spacer>
                    <v-icon @click="showAdvancedFilters = !showAdvancedFilters">mdi-close-thick</v-icon>
                </v-card-title>

                <v-card-text>
                    <!-- <v-row>
                       <v-col cols="8">
                            <v-text-field label="Nome Evento:" type="text" v-model="filter.name"></v-text-field>
                        </v-col>
                    </v-row> -->

                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Data Inicial:" type="date" v-model="filter.eventDate"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Data Encerramento:" type="date" v-model="filter.eventEndingDate"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <div align="right" style="width: 100%">
                            <v-btn :color="$store.state.colors.primary"
                                dark
                                large
                                @click="applyFilter()">
                                    buscar
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import SistemMessage from "../../global/SistemMessage";
import EventService from "../../../services/EventService";
import csvHandler from "../../../services/csvHandler"
export default {
    name: "EventList",

    components: { SistemMessage },

    data: () => ({
        showEventList: true,
        showAdvancedFilters: false,
        elementsNotReady: false,
        findField: undefined,
        eventListHeader: [
            { text: 'ID', align: 'start', sortable: true, value: 'id' },
            { text: 'Nome do Evento', sortable: true, value: 'name', width: "12%" },
            { text: 'Descrição', sortable: true, value: 'description', width: "42%" },
            { text: 'Data de Inicio', sortable: true, value: 'eventDate' },
            { text: 'Data Encerramento', sortable: true, value: 'eventEndingDate' },
            { text: 'Editar', value: 'edit', align: "end", sortable: false },
            { text: 'Adicionar Associado', value: 'add', align: "end", sortable: false },
            { text: 'Remover', value: 'delete', align: "end", sortable: false },
        ],
        eventList: undefined,
        filter: {
            id: undefined,
            name: undefined,
            eventDate: undefined,
            eventEndingDate: undefined,
        }
    }),

    methods: {
        deleteEvent(event) {
            this.$refs.message
                .open(
                    "Exclusão de Evento",
                    "Tem certeza que deseja exluir este evento? O evento será excluido permanentemente.",
                    {color: this.$store.state.colors.danger, showSecondButton: true, firstButtonColor: this.$store.state.colors.danger, secondButtonColor: this.$store.state.colors.second}
                ).then(() => {
                    EventService
                        .remove(event.id, this.$store.state.token)
                        .then(response => {
                            this.$refs.message.open(
                                "Exclusão de Evento",
                                response.data.message,
                                {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                            )
                        })
                        .catch(error => {
                            this.$refs.message.open(
                                "Error ao exluir evento",
                                error.response.data.message,
                                {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                            )
                        })
                        .finally(() => this.loadEventList())
                })
        },

        editEvent(event) {
            this.$cookies.set("actualPage", `/eventos/${event.id}`)
            this.$router.push(`/eventos/${event.id}`)
        },

        addAssociate(event) {
            this.$router.push(`/eventos/${event.id}/associados`);
        },

        loadEventList() {
            this.elementsNotReady = true;
            EventService
                .getList(this.$store.state.token)
                .then(response => {
                    this.eventList = response.data.events;
                    this.eventList.forEach(event => {
                        event.eventDate = event.eventDate?.split("T")[0].split("-").join("/");
                    });
                    this.eventList.forEach(event => {
                        event.eventEndingDate = event.eventEndingDate?.split("T")[0].split("-").join("/");
                    })
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao buscar lista de eventos",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.elementsNotReady = false)
        },

        applyFilter() {
            EventService
                .getByFilter(this.filter, this.$store.state.token)
                .then(response => {
                    this.eventList = response.data.events;
                    this.eventList.forEach(event => {
                        event.eventDate = event.eventDate?.split("T")[0].split("-").join("/");
                        event.eventEndingDate = event.eventEndingDate?.split("T")[0].split("-").join("/");
                    })
                })
                .catch(error => {
                    if(error.response.data.status == 404) {
                        this.$refs.message.open(
                            "Filtro de eventos",
                            error.response.data.message,
                            {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                        )
                    }
                    else {
                        this.$refs.message.open(
                            "Erro ao aplicar filtro",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    }
                })
                .finally(() => this.showAdvancedFilters = false)
        },

        exportEventList() {
            csvHandler.exportEventList(this.eventList);
        }
    },

    created: function() {
        this.loadEventList();
    }
}
</script>