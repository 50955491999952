<template>
    <v-app>
    <Menu></Menu>
    <v-main>
        <v-container fluid style="padding: 25px;">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <FinanceFollowList></FinanceFollowList>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu"
import FinanceFollowList from "../components/widgets/finance/FinanceFollowList"

export default {
    name: "Boletos",
    components: { Menu, FinanceFollowList },
    data: () => ({}),
    beforeCreate: function() {
        if(!this.$store.state.user) this.$router.push("/login");
    }
    
}
</script>