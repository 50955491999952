import axios from 'axios';

const getList = (token) => axios.get(`${global.applicationApiEndpoint}/finance`, mountHeader(token));
const getById = (eventId, token) => axios.get(`${global.applicationApiEndpoint}/finance/${eventId}`, mountHeader(token));
const getByFilter = (filter, token) => axios.post(`${global.applicationApiEndpoint}/finance/filter`, filter, mountHeader(token));

const mountHeader = (token) => {
    return { headers: { 'token': token } };
}

export default {
    getList,
    getById,
    getByFilter,
}