<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Categorias</h2>
            
            <v-spacer></v-spacer>
            
            <v-btn 
                :color="showCategoriesList ? $store.state.colors.primary : $store.state.colors.primary" 
                dark
                large
                @click="showCategoriesList = !showCategoriesList">
                    {{showCategoriesList ? 'novo' : 'voltar'}}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <CategoriesList v-if="showCategoriesList"></CategoriesList>
            <CategoryForm v-else @close="showCategoriesList = !showCategoriesList"></CategoryForm>
        </v-card-text>
    </v-card>
</template>

<script>
    import CategoriesList from "./CategoriesList";
    import CategoryForm from "./CategoryForm";

    export default {
        name: "CategoriesMainComponent",

        components: { CategoriesList, CategoryForm },

        data: () => ({
            showCategoriesList: true,
        })
    }
</script>