<template>
    <v-card elevation="2">
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <v-text-field
                        v-model="findStudent"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        placeholder="Buscar"
                    ></v-text-field>
                </v-col>

                <v-col cols="8">
                    <div align="right" style="width: 100%">
                        <v-btn 
                            dark 
                            fab 
                            small
                            :color="$store.state.colors.primary"
                            @click="loadStudentList()">
                                <v-icon>mdi-refresh</v-icon>
                        </v-btn> &nbsp;
                        <v-btn :color="$store.state.colors.primary"
                            dark
                            large
                            @click="downloadCSV()">
                                exportar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <br>
            <v-data-table 
                :headers="studentListHeader" 
                :items="studentList" 
                :items-per-page="9999999999"
                hide-default-footer 
                :search="findStudent"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text':'Participantes por Página'}">

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon :color="$store.state.colors.primary" @click="editCourse(item)">mdi-pencil</v-icon>&nbsp;&nbsp;
                </template>
            </v-data-table>
        </v-card-text>
        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import StudentService from '../../../services/StudentService';
import SistemMessage from "../../global/SistemMessage";
import csvHandler from "../../../services/csvHandler";

export default {
    components: {SistemMessage},
    data: () => ({
        findStudent: undefined,
        studentListHeader: [
            { text: 'ID', align: 'start', sortable: true, value: 'id' },
            { text: 'Nome', sortable: true, value: 'name' },
            { text: 'CPF', sortable: true, value: 'cpf' },
            { text: 'Turma', sortable: true, value: 'course' },
            { text: 'Editar', sortable: true, value: 'actions', align: 'end'},
        ],

        studentList: undefined,
    }),

    methods: { 
        loadStudentList() {
            StudentService
                .getList(this.$store.state.token)
                .then(response => {
                    this.studentList = response.data.students
                })
                .catch(error => {
                    if(error.response.status == 500) {
                        this.$refs.message.open(
                            "Erro ao buscar lista de participantes",
                            error.response.data.message,
                            {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                        )
                    }
                })
        },

        downloadCSV() {
            csvHandler.exportStudentsList(this.studentList);
        },

        editCourse(item) {
            this.$cookies.set("actualPage", "/participante/" + item.id);
            this.$router.push("/participante/" + item.id);
        }
    },

    created: function() {
        this.loadStudentList();
    }
}
</script>