<template>
    <v-app>
    <Menu></Menu>
    <v-main>
        <v-container fluid style="padding: 25px;">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <v-card elevation="0">
                        <v-card-title>
                            <h2>Usuários</h2>

                            <v-spacer></v-spacer>

                            <v-btn 
                                :color="$store.state.colors.primary" 
                                dark 
                                large
                                @click="goback()">
                                    voltar
                            </v-btn> 
                        </v-card-title>

                        <v-card-text>
                            <UserEditForm @close-form="goback()"></UserEditForm>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu"
import UserEditForm from "../components/widgets/user/UserEditForm"

export default {
    name: "Usuarios",
    components: { Menu, UserEditForm },
    data: () => ({

    }),
    
    methods: {
        goback() {
            this.$cookies.set("actualPage", "/usuarios")
            this.$router.push("/usuarios")
        }
    },
    beforeCreate: function() {
        if(!this.$store.state.user) this.$router.push("/login");
    }
}
</script>