<template>
    <v-card elevation="2">

        <!-- Create a skeletion loader that shows up when users list is loading -->
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, 
                list-item-two-line, 
                list-item-two-line, 
                list-item-two-line">
        </v-skeleton-loader>

        <!-- this card holds all the page components -->
        <v-card-text v-else>
            <v-row>
                <v-col cols="4">
                    <!-- this field must be use to filter the users on the list -->
                    <v-text-field
                        v-model="tableQuery"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        placeholder="Buscar">
                    </v-text-field>
                </v-col>

                <!-- this collumn define all the table button actions like export csv -->
                <v-col cols="8" width="100%" align="right">
                    <!-- this button will refresh the list -->
                    <v-btn 
                        dark 
                        fab 
                        small
                        :color="$store.state.colors.primary"
                        @click="loadList()">
                            <v-icon>mdi-refresh</v-icon>
                    </v-btn> &nbsp;

                    <!-- export csv action button -->
                    <v-btn 
                        dark
                        large
                        :color="$store.state.colors.primary"
                        @click="downloadCSV()">
                            exportar
                    </v-btn>
                </v-col>
            </v-row>

            <br>
            
            <!-- users list table -->
            <v-data-table
                :headers="usersListHeaders"
                :items="usersList"
                :items-per-page="9999999999"
                hide-default-footer
                :search="tableQuery"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text':'Usuários por Página'}">

                <!-- this template define the table internal actions -->
                <template v-slot:[`item.edit`]="{ item }">
                    <v-btn icon :color="$store.state.colors.primary" @click="editUser(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                    <v-btn icon :color="$store.state.colors.danger" @click="deleteUser(item)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- checkout the component code to see how to use it -->
        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import csvHandler from "../../../services/csvHandler";
    import UserService from "../../../services/UserService";
    import SistemMessage from "../../global/SistemMessage";

    export default {
        components: { SistemMessage },

        data: () => ({
            // define skeleton loader display
            elementsNotReady: true,

            // this field will recieve the string to filter the users on the list
            tableQuery: undefined,

            // table headers
            usersListHeaders: [
                { text: 'Id', align: 'start', sortable: true, value: 'id' },
                { text: 'Nome', sortable: true, value: 'name' },
                { text: 'Email', sortable: true, value: 'email' },
                { text: 'Editar', sortable: true, value: 'edit', align: 'end' },
                { text: 'Remover', sortable: true, value: 'delete', align: 'end' },
            ],

            // this list will be filled by the loadList method
            usersList: undefined,
        }),

        methods: {
            downloadCSV() {
                // checkout the function code to se how to use it
                const title = `lista_usuarios_${Date.now()}`;
                const csvHeader = `id;nome;cpf;email;dataCriacao\n`;
                let csvContent = "";
                this.usersList.forEach(user => {
                    // formating date to be more human readable
                    const creationDate = user.creationDate.split("T")[0].split("-").reverse().join("/");
                    csvContent += `${user.id};${user.name};${user.cpf};${user.email};${creationDate}\n`;
                })
                csvHandler.exportFile(title, csvHeader, csvContent);
            },

            loadList() {
                // call the skeleton loader
                this.elementsNotReady = true;

                // call the backend
                UserService
                    .getList(this.$store.state.token)
                    .then(response => {
                        this.usersList = response.data.users;
                    })
                    .catch(error => {
                        this.$refs.message.open(
                            "Error ao buscar Lista de usuários",
                            error.response.data.message,
                            {
                                color: this.$store.state.colors.danger, 
                                showSecondButton: false, 
                                firstButtonColor: this.$store.state.colors.danger
                            }
                        )
                    })
                    .finally(() => this.elementsNotReady = false)
            },

            deleteUser(user) {
                this.$refs.message
                    .open(
                        "Exclusão de Usuário",
                        "Tem certeza que deseja excluir este usuário? Essa ação é irreversível e este usuário não poderá mais acessar o painel.",
                        {
                            color: this.$store.state.colors.danger, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.danger
                        }
                    )
                    .then(() => {
                        UserService
                            .remove(user.id, this.$store.state.token)
                            .then(response => {
                                this.$refs.message.open(
                                    "Exclusão de Usuário",
                                    response.data.message,
                                    {
                                        color: this.$store.state.colors.primary, 
                                        showSecondButton: false, 
                                        firstButtonColor: this.$store.state.colors.primary
                                    }
                                );
                                this.usersList = this.usersList.filter(element => {
                                    if(element.id != user.id) return element;
                                })
                            })
                            .catch(error => {
                                this.$refs.message.open(
                                    "Erro ao exluir usuário",
                                    error.response.data.message,
                                    {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                                )
                            })
                    });
            },

            editUser(user) {
                this.$cookies.set("actualPage", "/usuarios/" + user.id);
                this.$router.push("/usuarios/" + user.id);
            }
        },

        created: function() {
            this.loadList();
        }
}
</script>