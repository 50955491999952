<template>
    <v-card elevation="2" :loading="cardLoading">
        <v-card-text>
            <div style="padding: 25px;">
                <div align="left">
                    <h3>Novo Usuário:</h3>
                </div><br>
                <!-- parte refernte aos dados pessoais do usuario -->
                <v-row>
                    <v-col cols="6">
                        <!-- campo nome -->
                        <v-text-field
                            label="Nome:"
                            type="text"
                            :color="$store.state.colors.primary"
                            :rules="[rules.required]"
                            v-model="newUserData.name">
                        </v-text-field>
                    </v-col>
                        
                    <v-col cols="4">
                        <!-- campo cpf -->
                        <v-text-field
                            label="CPF:"
                            v-mask="'###.###.###-##'"
                            type="text"
                            :color="$store.state.colors.primary"
                            :rules="[rules.required]"
                            v-model="newUserData.cpf">
                        </v-text-field>
                    </v-col>

                    <v-col cols="2">
                        <!-- campo data data cadastro -->
                        <v-text-field
                            label="Data Cadastro:"
                            :color="$store.state.colors.primary"
                            type="date"
                            v-model="newUserData.creationDate">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- parte referente aos contatos do usuario -->
                <v-row>
                    <v-col cols="6">
                        <!-- campo email -->
                        <v-text-field
                            label="Email:"
                            :color="$store.state.colors.primary"
                            type="email"
                            :rules="[rules.required]"
                            v-model="newUserData.email">
                        </v-text-field>
                    </v-col>

                    <v-col cols="3">
                        <!-- campo senha -->
                        <v-text-field
                            label="Senha:"
                            :color="$store.state.colors.primary"
                            :type="showPassword ? 'text' : 'password'"
                            :rules="[rules.required]"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword"
                            v-model="newUserData.password">
                        </v-text-field>
                    </v-col>

                    <v-col cols="3">
                        <!-- campo senha2 -->
                        <v-text-field
                            label="Repita a Senha:"
                            :color="$store.state.colors.primary"
                            :type="showSecondPassword ? 'text' : 'password'"
                            :append-icon="showSecondPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showSecondPassword = !showSecondPassword"
                            :rules="[rules.required]"
                            v-model="secondPassword">
                        </v-text-field>
                    </v-col>
                </v-row>

                <br>
                <div align="right">
                    <v-btn 
                        :color="$store.state.colors.primary"
                        :loading="cardLoading"
                        dark
                        large
                        @click="createNewUser()">
                            salvar
                    </v-btn>
                </div>
            </div>
        </v-card-text>
        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>


<script>
import UserService from "../../../services/UserService";
import SistemMessage from "../../global/SistemMessage";

export default {
    name: "UserCreateForm",
    components: { SistemMessage },
    data: () => ({
        secondPassword: undefined, 
        showPassword: false,
        showSecondPassword: false,
        validPassword: false,       
        cardLoading: false,
        newUserData: { 
            name: undefined, 
            cpf: undefined, 
            email: undefined,
            password: undefined,
            creationDate: undefined,
        },
        rules: {
            required: value => !!value || "Campo obrigatório"
        }
    }),
    methods: { 
        validateSecondPassword() {
            if(this.secondPassword != this.newUserData.password) {
                return false;
            } else {
                return true;
            }
        },

        validateFields() {
            if(
                !this.newUserData.name || !this.newUserData.cpf || 
                !this.newUserData.email || !this.newUserData.password
            ) {
               return false;
            }
            return true;
        },

        createNewUser() {
            if(!this.validateSecondPassword()) {
                this.$refs.message.open(
                    "Erro ao cadastrar Usuário",
                    "As senhas precisam ser idênticas",
                    {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }

            if(!this.validateFields()) {
                this.$refs.message.open(
                    "Campos obrigatórios em branco",
                    "Existem campos obrigatórios que ainda não foram preenchidos, Insira todas as informações necessárias e tente novamente.",
                    {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                );
                return false;
            }

            this.cardLoading = true;

            UserService
                .create(this.newUserData, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Cadastro de Usuário",
                        response.data.message,
                        {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                    ).then(() => this.$emit("close-form"))
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao Cadastrar Usuário",
                        error.response.data.message,
                        {color: this.$store.state.colors.danger, showSecondButton: false, firstButtonColor: this.$store.state.colors.danger}
                    )
                })
                .finally(() => this.cardLoading = false);
        }
    },
}
</script>