import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue"
import Associates from '../views/Associates.vue'
import Categories from '../views/Categories.vue'
import CategoryEdit from '../views/CategoryEdit.vue';
import Finance from '../views/Finance.vue'
import Users from '../views/Users.vue'
import Profile from '../views/Profile.vue'
import Events from '../views/Events.vue'
import Speaker from '../views/Speaker';
import Course from '../views/Course.vue'
import CourseEdit from '../views/CourseEdit.vue'
import AddAssociateToEvent from '../views/AddAssociateToEvent.vue'
import SpeakerEdit from '../views/SpeakerEdit.vue'
import EventEdit from '../views/EventEdit.vue'
import UsersEdit from '../views/UsersEdit.vue'
import AssociatesEdit from '../views/AssociatesEdit.vue'
import StudentEdit from '../views/StudentEdit.vue';
import ClassEdit from '../views/ClassEdit.vue';
import ExternalAssociate from "../views/ExternalAssociate.vue";
import ExternalAssociateFinance from "../views/ExternalAssociateFinance.vue";

Vue.use(VueRouter)

const routes = [
  { path: '/home', name: 'Home', component: Home },
  { path: '/login', name: 'Login', component: Login },
  { path: '/associados', name: 'Associados', component: Associates },
  { path: '/categorias', name: 'Categorias', component: Categories },
  { path: '/categoria/:categoryId', name: 'Edição de Categoria', component: CategoryEdit },
  { path: '/financeiro', name: 'Acompanhamento Financeiro', component: Finance },
  { path: '/usuarios', name: 'Usuários', component: Users },
  { path: '/perfil', name: 'Perfil', component: Profile },
  { path: '/eventos', name: 'Eventos', component: Events },
  { path: '/palestrantes', name: "Palestrantes", component: Speaker },
  { path: '/curso', name: "Controle de Curso", component: Course },
  { path: '/curso/:courseId', name: "Edição de Curso", component: CourseEdit },
  { path: '/curso/presenca/:studentId', name: "Adição de Participante", component: Course },
  { path: '/eventos/:eventId/associados', name: 'Adição de Associado a um Evento', component: AddAssociateToEvent },
  { path: '/palestrante/:id', name: 'Edição de Palestrante', component: SpeakerEdit },
  { path: '/eventos/:id', name: 'Edição de Evento', component: EventEdit },
  { path: '/usuarios/:id', name: 'Edição de Usuario', component: UsersEdit },
  { path: '/associados/:id', name: 'Edição de Associado', component: AssociatesEdit},
  { path: '/participante/:id', name: 'Edição de Participante', component: StudentEdit},
  { path: '/aula/:id', name: 'Edição de Aula', component: ClassEdit },
  { path: '/associado-externo', name: 'Associado Externo', component: ExternalAssociate },
  { path: '/associado-externo-boleto', name: 'Visualizar Boleto', component: ExternalAssociateFinance },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
