<template>
    <v-app>
    <Menu></Menu>
    <v-main>
        <v-container fluid style="padding: 25px;">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <StudentForm></StudentForm>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu"
import StudentForm from "../components/widgets/students/StudentEdit.vue"

export default {
    components: {Menu, StudentForm}
}
</script>