import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    actualPage: undefined,

    applicationVersion: "1.8.2",

    colors: {
      primary: "#081951",
      secundary: "blue lighten-4",
      danger: "red darken-4",
      warning: "yellow darken-3"
    },

    eventList: [
      {id: '222334', name: 'Apresentação Advogados', eventDate: '15/06/2021', begin: '17:00', finish: '21:00'},
      {id: '432423', name: 'Bate papo OAB', eventDate: '10/04/2021', begin: '13:00', finish: '18:00'},
      {id: '543534', name: 'Palestra', eventDate: '25/05/2021', begin: '08:00', finish: '12:00'},
      {id: '654565', name: 'Direito Financeiro', eventDate: '05/05/2021', begin: '15:00', finish: '20:00'},
    ],

    speakersList: [
      { id: "123", name: "Ricardo Teixeira", email: "ricard.teixeira@gmail.com", phone: "(11) 98873-0092", subjects: "Finanças" },
      { id: "124", name: "Marcelo Costa", email: "marcelo.costa@gmail.com", phone: "(11) 94435-4532", subjects: "Direito Financeiro" }, 
      { id: "125", name: "Luiza Vasconcelos", email: "luiza.vasconcelos@gmail.com", phone: "(13) 2254-3364", subjects: "Direito" }, 
      { id: "126", name: "Ricardo Teixeira", email: "ricard.teixeira@gmail.com", phone: "(15) 3258-4487", subjects: "Impostos" },
    ],

    courseList: [
      { 
          id: "100", 
          name: "Modulo 1", 
          year: "2020", 
          semester: "1º", 
          description: "dhsaihdasuhdasihdusahdaushduashduashdasuihdasuidhsauidhasdas",
          sunday: false,
          monday: true,
          tuesday: false, 
          wednesday: true,
          thursday: false,
          friday: false,
          saturday: false,
          initHour: "17:00"
      },
      { 
          id: "101", 
          name: "Modulo 2", 
          year: "2020", 
          semester: "2º", 
          description: "dashduashduashduashudhsaihdasihdiashdas dhsaudhsiahdsahduashduasi" ,
          sunday: false,
          monday: false,
          tuesday: true, 
          wednesday: false,
          thursday: true,
          friday: false,
          saturday: false,
          initHour: "17:00"
      },
      { 
          id: "102", 
          name: "Modulo 3", 
          year: "2021", 
          semester: "1º", 
          description: "dhsaudhasdhasuhdasuhdasuihdiasuhdasuihu dshaudhasiduhasuid dhasuidhasihdiasuh", 
          sunday: false,
          monday: false,
          tuesday: false, 
          wednesday: true,
          thursday: false,
          friday: true,
          saturday: false,
          initHour: "17:00"
      },
    ],

    categoriesList: [
      { id: "15", name: "Categoria 1", description: "Sagittis aenean aliquet purus, et.", cost: 200.00 },
      { id: "16", name: "Categoria 2", description: "Consectetur integer dapibus bibendum, vehicula.", cost: 100.00 },
      { id: "17", name: "Categoria 3", description: "Feugiat porta libero pellentesque, praesent.", cost: 500.00 },
    ],
    
    user: undefined,
  },

  mutations: {
  },

  actions: {
  },
  
  modules: {
  }
})
