<template>
    <v-card elevation="10" :loading="cardLoading ? $store.state.colors.primary : null">
        <div style="padding: 25px;"> 
            <div align="center">
                <h3>Faça seu Login</h3>
            </div><br>

            <v-form>
                <v-text-field 
                    label="Login:"
                    type="text"
                    :color="$store.state.colors.primary"
                    :rules="[rules.required]"
                    v-model="userLoginData.email">
                </v-text-field>

                <v-text-field
                    label="Senha"
                    :color="$store.state.colors.primary"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[rules.required]"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="performUserLogin()"
                    v-model="userLoginData.password">
                </v-text-field>
                <div align="center" style="margin-top: -10px">
                    <v-btn text :color="$store.state.colors.primary">esqueci minha senha</v-btn>
                </div>
                <br>
                <div align="center">
                    <v-btn 
                        large 
                        dark 
                        :color="$store.state.colors.primary"
                        :loading="cardLoading"
                        @click="performUserLogin()">
                            entrar
                    </v-btn>
                </div>
                <br>
                <div align="center">
                    <small style="color: gray;">v{{$store.state.applicationVersion}}</small>
                </div>
            </v-form>
        </div>

        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
    import UserService from "../../../services/UserService";
    import SistemMessage from "../../global/SistemMessage";

    export default {
        name: "UserLoginForm",

        components: { SistemMessage },

        data: () => ({
            userLoginData: { email: undefined, password: undefined },
            showPassword: false,
            cardLoading: false,
            rules: {
                required: value => !!value || "Campo obrigatório"
            }
        }),

        methods: {
            validateFields() {
                if(!this.userLoginData.email || !this.userLoginData.password) 
                    return false;
                else
                    return true;
            },

            performUserLogin() {
                if(!this.validateFields()) {
                    this.$refs.message.open(
                        "Erro de Login",
                        "Os campos 'Login' e 'Senha' são obrigatórios",
                        { 
                            color: this.$store.state.colors.warning,
                            firstButtonColor: this.$store.state.colors.warning
                        }
                    );
                    return false;
                }

                this.cardLoading = true;

                UserService
                    .login(this.userLoginData)
                    .then(response => {
                        this.$cookies.set("user", response.data.user);
                        this.$store.state.user = response.data.user;
                        this.$cookies.set("token", response.data.token);
                        this.$store.state.token = response.data.token;
                        this.$cookies.set("actualPage", '/home')
                        this.$router.push("/home");
                    })
                    .catch(error => {
                        if(error.response == undefined) {
                            this.$refs.message.open(
                                "Erro ao realizar login",
                                "Não foi possível se conectar ao servidor.",
                                { 
                                    color: this.$store.state.colors.danger, 
                                    showSecondButton: false,
                                    firstButtonColor: this.$store.state.colors.danger
                                }
                            )
                        } else {
                            this.$refs.message.open(
                                "Erro ao realizar login",
                                error.response.data.message,
                                { 
                                    color: this.$store.state.colors.danger, 
                                    showSecondButton: false,
                                    firstButtonColor: this.$store.state.colors.danger
                                }
                            )
                        }
                        
                    })
                    .finally(() => this.cardLoading = false);
            }
        }
    }
</script>