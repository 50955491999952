import axios from 'axios';

const create = (newAssociate, token) => {
    return axios.post(`${global.applicationApiEndpoint}/associates`, newAssociate, _mountAuthenticateHeader(token))
}

const createExternal = (newAssociate) => {
    return axios.post(`${global.applicationApiEndpoint}/associates/external`, newAssociate, {})
}

const getList = (token) => {
    return axios.get(`${global.applicationApiEndpoint}/associates`, _mountAuthenticateHeader(token))
}

const getById = (associateId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/associates/${associateId}`, _mountAuthenticateHeader(token));
}

const update = (associate, associateId, token) => {
    return axios.put(`${global.applicationApiEndpoint}/associates/${associateId}`, associate, _mountAuthenticateHeader(token));
}

const remove = (associateId, token) => {
    return axios.delete(`${global.applicationApiEndpoint}/associates/${associateId}`, _mountAuthenticateHeader(token));
}

const findByFilter = (filter, token) => {
    return axios.post(`${global.applicationApiEndpoint}/associates/filter`, filter, _mountAuthenticateHeader(token))
}

const aprove = (associateId, token) => {
    return axios.put(`${global.applicationApiEndpoint}/associates/aprove/${associateId}`, {}, _mountAuthenticateHeader(token))
}

const updateRegisteredAssociatesList = (associates, eventId, event, token) => {
    const payload = {
        associates: associates,
        event: event,
    }
    return axios.post(`${global.applicationApiEndpoint}/eventAssociate/${eventId}`, payload, _mountAuthenticateHeader(token))
}

const getBoletoByAssociateDocument = (payload) => {
    return axios.patch(`${global.applicationApiEndpoint}/associates/boleto`, payload, _mountAuthenticateHeader(undefined));
}

const _mountAuthenticateHeader = (token) => {
    return { headers: { 'token': token } };
}


export default {
    create,
    getList,
    getById,
    update,
    remove,
    findByFilter,
    aprove,
    updateRegisteredAssociatesList,
    createExternal,
    getBoletoByAssociateDocument
}   