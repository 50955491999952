<template>
    <v-card elevation="2" :loading="cardLoading">
        <v-skeleton-loader
            v-if="elementsNotReady"
            elevation="2"
            type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line">
        </v-skeleton-loader>

        <v-card-text v-else>
            <div style="padding: 25px;">
                <div align="left">
                    <h3>Atualização de Usuário:</h3>
                </div><br>
                <!-- parte refernte aos dados pessoais do usuario -->
                <v-row>
                    <v-col cols="6">
                        <!-- campo nome -->
                        <v-text-field
                            label="Nome:"
                            type="text"
                            :color="$store.state.colors.primary"
                            :rules="[rules.required]"
                            v-model="user.name">
                        </v-text-field>
                    </v-col>
                        
                    <v-col cols="4">
                        <!-- campo cpf -->
                        <v-text-field
                            label="CPF:"
                            v-mask="'###.###.###-##'"
                            type="text"
                            :color="$store.state.colors.primary"
                            :rules="[rules.required]"
                            v-model="user.cpf">
                        </v-text-field>
                    </v-col>

                    <v-col cols="2">
                        <!-- campo data data cadastro -->
                        <v-text-field
                            label="Data Cadastro:"
                            :color="$store.state.colors.primary"
                            type="date"
                            v-model="user.creationDate">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- parte referente aos contatos do usuario -->
                <v-row>
                    <v-col cols="6">
                        <!-- campo email -->
                        <v-text-field
                            label="Email:"
                            :color="$store.state.colors.primary"
                            type="email"
                            :rules="[rules.required]"
                            v-model="user.email">
                        </v-text-field>
                    </v-col>

                    <v-col cols="3">
                        <!-- campo senha -->
                        <v-text-field
                            label="Senha:"
                            :color="$store.state.colors.primary"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword"
                            v-model="user.password">
                        </v-text-field>
                    </v-col>

                    <v-col cols="3">
                        <!-- campo senha2 -->
                        <v-text-field
                            label="Repita a Senha:"
                            :color="$store.state.colors.primary"
                            :type="showSecondPassword ? 'text' : 'password'"
                            :append-icon="showSecondPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showSecondPassword = !showSecondPassword"
                            v-model="secondPassword">
                        </v-text-field>
                    </v-col>
                </v-row>

                <br>
                <div align="right">
                    <v-btn 
                        :color="$store.state.colors.primary"
                        :loading="cardLoading"
                        dark
                        large
                        @click="updateUser()">
                            salvar
                    </v-btn>
                </div>
            </div>
        </v-card-text>
        <SistemMessage ref="message"></SistemMessage>
    </v-card>
</template>

<script>
import SistemMessage from "../../global/SistemMessage";
import UserService from "../../../services/UserService";

export default {
    components: {SistemMessage},
    
    data: () => ({
        secondPassword: undefined, 
        showPassword: false,
        showSecondPassword: false,
        validPassword: false,       
        cardLoading: false,
        user: undefined,
        elementsNotReady: true,
        rules: {
            required: value => !!value || "Campo obrigatório"
        }
    }),

    methods: {
        validateSecondPassword() {
            if(this.secondPassword != this.user.password) {
                return false;
            } else {
                return true;
            }
        },

        validateFields() {
            if(!this.user.name || !this.user.cpf || !this.user.email)
               return false;
            return true;
        },

        updateUser() {
            if(this.user.password && !this.validateSecondPassword()) {
                this.$refs.message.open(
                    "Erro ao atualizar Usuário",
                    "As senhas precisam ser idênticas",
                    {color: this.$store.state.colors.warning, showSecondButton: false, firstButtonColor: this.$store.state.colors.warning}
                )
                return false;
            }

            if(!this.validateFields()) {
                this.$refs.message.open(
                    "Campos obrigatórios em branco",
                    "Existem campos obrigatórios que ainda não foram preenchidos, Insira todas as informações necessárias e tente novamente.",
                    {color: this.$store.state.colors.primary, showSecondButton: false, firstButtonColor: this.$store.state.colors.primary}
                );
                return false;
            }

            this.cardLoading = true;

            UserService
                .update(this.user, this.$store.state.token)
                .then(response => {
                    this.$refs.message.open(
                        "Atualização de usuário",
                        response.data.message,
                        {
                            color: this.$store.state.colors.primary, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.primary
                        }
                    ).then(() => this.$emit("close-form"));
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao atualizar Usuário",
                        error.response.data.message,
                        {
                            color: this.$store.state.colors.danger, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.danger
                        }
                    )
                })
                .finally(() => this.cardLoading = false);
        },

        loadUser(userId) {
            UserService            
                .getById(userId, this.$store.state.token)
                .then(response => {
                    this.user = response.data.user;
                    this.user.password = undefined;
                    this.user.creationDate = this.user.creationDate.split("T")[0];
                })
                .catch(error => {
                    this.$refs.message.open(
                        "Erro ao realizar leitura dos dados de Usuário",
                        error.response.data.message,
                        {
                            color: this.$store.state.colors.danger, 
                            showSecondButton: false, 
                            firstButtonColor: this.$store.state.colors.danger
                        }
                    )
                }).finally(() => this.elementsNotReady = false);
        },
    },

    created: function () {
        this.loadUser(this.$route.params.id);
    }
}
</script>