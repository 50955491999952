<template>
    <v-app>
        <Menu></Menu>
        <v-main>
            <v-container fluid style="padding: 25px;">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <CourseEdit></CourseEdit>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/global/Menu";
import CourseEdit from "../components/widgets/course/CourseEdit";

export default {
    name: "Course",
    
    components: {Menu, CourseEdit},
    
    data: () => ({
        categoryId: undefined,
    }),

    beforeCreate: function() {
        if(!this.$store.state.user) this.$router.push("/login");
    }
}
</script>