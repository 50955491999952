import axios from 'axios';

const create = (newClass, token) => {
    return axios.post(`${global.applicationApiEndpoint}/classes`, newClass, _mountAuthenticateHeader(token))
}

const getList = (token) => {
    return axios.get(`${global.applicationApiEndpoint}/classes`, _mountAuthenticateHeader(token))
}

const getById = (classId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/classes/${classId}`, _mountAuthenticateHeader(token));
}

const update = (updateClass, classId, token) => {
    return axios.put(`${global.applicationApiEndpoint}/classes/${classId}`, updateClass, _mountAuthenticateHeader(token));
}

const remove = (classId, token) => {
    return axios.delete(`${global.applicationApiEndpoint}/classes/${classId}`, _mountAuthenticateHeader(token));
}

const getByCourseId = (courseId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/classes/course/${courseId}`, _mountAuthenticateHeader(token));
}

const getStudentsByClassId = (classId, token) => {
    return axios.get(`${global.applicationApiEndpoint}/class/${classId}`, _mountAuthenticateHeader(token));
}

const updateClassByCourse = (updateClass, token) => {
    return axios.post(`${global.applicationApiEndpoint}/classes/course`, updateClass, _mountAuthenticateHeader(token));
}

const _mountAuthenticateHeader = (token) => {
    return { headers: { 'token': token } };
}


export default {
    create,
    getList,
    getById,
    update,
    remove,
    getByCourseId,
    updateClassByCourse,
    getStudentsByClassId
}   