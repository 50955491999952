<template>
  <v-app>
    <v-app-bar app :color="$store.state.colors.primary" dark>
        <v-toolbar-title>
            Associação Brasileira de Direito Financeiro
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
        <v-container style="padding-right: 100px; padding-left: 100px;">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <ExternalAssociateBoleto />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ExternalAssociateBoleto from "../components/widgets/boletos/ExternalAssociateBoleto.vue";

export default {
  name: 'Home',
  components: { ExternalAssociateBoleto },
}

</script>