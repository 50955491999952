<template>
    <v-card elevation="0" app>
        <v-card-title>
            <h2>Controle de Curso PJT</h2>
        </v-card-title>

        <v-card-text>
            <v-tabs v-model="tab" :color="$store.state.colors.primary">
                <v-tab>Turmas</v-tab>
                <v-tab>Alunos</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <CourseMainComponent></CourseMainComponent>
                </v-tab-item>

                <v-tab-item>
                    <StudentsMainComponent></StudentsMainComponent>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
    import CourseMainComponent from "./CourseMainComponent";
    import StudentsMainComponent from "../students/StudentsMainComponent";

    export default {
        name: "CategoriesMainComponent",

        components: { CourseMainComponent, StudentsMainComponent },

        data: () => ({
            tab: null,
            showCourseList: true,
        })
    }
</script>