const getBaseText = (associate) => {
    const fullname = removeSimbols(associate.name);
    const name = getFirstName(fullname);
    const lastName = getLastNames(fullname);
    const address = removeSimbols(associate.address);
    const complement = removeSimbols(associate.complement);
    const burgh = removeSimbols(associate.burgh);

    let baseText = `BEGIN:VCARD`;
    baseText += `\nVERSION:2.1`;
    baseText += `\nN;LANGUAGE=pt-br:;${fullname}`;
    baseText += `\nFN:${lastName}, ${name}`;
    baseText += `\nTEL;WORK;VOICE:${associate.companyPhone || ""}`;
    baseText += `\nTEL;HOME;VOICE:${associate.homePhone || ""}`;
    baseText += `\nTEL;CELL;VOICE:${associate.cellphone || ""}`;
    baseText += `\nTEL;WORK;FAX:`;
    baseText += `\nADR;WORK;PREF:`;
    baseText += `\nLABEL;WORK;PREF:${address}, ${associate.number} ${complement} ${burgh} ${associate.uf} ${associate.zipcode}`;
    baseText += `\nX-MS-OL-DEFAULT-POSTAL-ADDRESS:`;
    baseText += `\nEMAIL;PREF;INTERNET:${associate.primaryEmail}`;
    baseText += `\nX-MS-OL-DESIGN;CHARSET=utf-8:`;
    baseText += `\nREV:20210702T023426Z`;
    baseText += `\nEND:VCARD`;

   return baseText;
}

const generateVCF = (associate) => {
    const baseText = getBaseText(associate);
    download(associate.name.split(" ").join("_"), baseText);
}

const download = (filename, baseText) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/plain;charset=utf-8,'+encodeURIComponent(baseText, 'utf-8');
    downloadLink.target = '_blank';
    downloadLink.download = filename + Date.now() + ".vcf";
    downloadLink.click();
}

const removeSimbols = (text) => {
    if (!text) return "";
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

const getFirstName = (fullname) => {
    const auxArray = fullname.split(" ");
    return auxArray[0];
}

const getLastNames = (fullname) => {
    const auxArray = fullname.split(" ");
    let lastname = "";
    for (let index = 1; index < auxArray.length; index++) {
        lastname += auxArray[index] + " ";
        console.log(lastname);
    }
    return lastname.trim();
}

module.exports = {
    generateVCF
}